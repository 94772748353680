import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { AuthGmsClientCallbackComponent } from './auth/components/auth-gms-client-callback.component';
import { PermissionAction, PermissionResource } from './auth/models/role.model';
import { AuthGuard, RoleGuardData } from './auth/services/auth-guard.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { RepublishComponent } from './pages/republish/republish.component';
import { RepublishSummaryComponent } from './pages/republish_summary/republish_summary.component';
import { OrganisationManagementComponent } from './pages/organisation-management/organisation_management.component';

const GUARDS = environment.importerAuthentication?.enabled ? [AuthGuard] : [];
const SUPER_ADMIN_REQUIREMENTS: RoleGuardData = {
  resource: PermissionResource.All,
  actions: [PermissionAction.All]
};
const ORGANISATION_MANAGEMENT_USER_REQUIREMENTS: RoleGuardData = {
  resource: PermissionResource.OrganisationManagementUser,
  actions: [PermissionAction.All]
};
const ORGANISATION_MANAGEMENT_ADMIN_REQUIREMENTS: RoleGuardData = {
  resource: PermissionResource.OrganisationManagementAdmin,
  actions: [PermissionAction.All]
};

export const ROUTES: Routes = [
  {
    path: 'auth/callback',
    component: AuthGmsClientCallbackComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'republish',
    pathMatch: 'full',
    component: RepublishComponent,
    canActivate: GUARDS,
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: GUARDS,
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'summary/:id',
    component: SummaryComponent,
    canActivate: GUARDS,
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'republish_summary/:id',
    component: RepublishSummaryComponent,
    canActivate: GUARDS,
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'organisation-management',
    component: OrganisationManagementComponent,
    canActivate: GUARDS,
    data: {
      allowedResources: [SUPER_ADMIN_REQUIREMENTS, ORGANISATION_MANAGEMENT_USER_REQUIREMENTS, ORGANISATION_MANAGEMENT_ADMIN_REQUIREMENTS]
    }
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/log-out/log-out.module').then(m => m.LogOutModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
