import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExportAttributesDto, FileTypeDto, Level } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';

@Component({
  selector: 'ci-export-attributes-form',
  templateUrl: './export-attributes-form.component.html',
  styleUrls: ['./export-attributes-form.component.scss']
})
export class ExportAttributesFormComponent implements OnInit {
  constructor() {}

  Action = PopupAction;

  @Input() entity: FileTypeDto;
  @Input() action: PopupAction;

  @Output() allValid: EventEmitter<boolean> = new EventEmitter();
  @Output() allRequired: EventEmitter<boolean> = new EventEmitter();

  public LEVEL: string[] = Object.values(Level);

  ngOnInit(): void {
    this.validate();
  }

  onSelect(event, index: number) {
    this.entity.exportAttributes[index].level = event.value;
  }

  add() {
    const exportAttributes = {} as ExportAttributesDto;
    this.entity.exportAttributes.push(exportAttributes);
  }

  remove(index: number) {
    this.entity.exportAttributes.splice(index, 1);
  }

  validateKey(key: string): string {
    let result: string;
    if (key && !key.match(/^[a-zA-Z_$][a-zA-Z_$0-9]*$/)) {
      result = 'has invalid characters';
    }
    this.validate();
    return result;
  }

  validate() {
    const allRequired = !this.entity.exportAttributes?.find(ea => !ea.level || !ea.key || !ea.value);
    const allValid = !this.entity.exportAttributes?.find(ea => ea.key && !ea.key.match(/^[a-zA-Z_$][a-zA-Z_$0-9]*$/));
    this.allRequired.emit(allRequired);
    this.allValid.emit(allValid);
  }
}
