import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRepublishObjectData } from '@coin/importer/dto/util';
import { environment } from '@coin/shared/util-environments';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
// TODO move to republish service
export class ObjectRefreshService {
  constructor(
    private http: HttpClient,
    private toast: ToastService
  ) {}

  sendRepublishObjectData(republishObjectData: IRepublishObjectData): Observable<void> {
    const url = `${environment.api.importerService}/republishObject`;
    return this.http.put(url, republishObjectData).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The Request could not be sent - please try again later');
    } else if (error.status === 404) {
      this.toast.errorWithRedirect(`The requested page could not be found.`, '404');
    } else {
      this.toast.error(`Error: "${error.error?.message || 'unknown'}"`);
    }
    return throwError(error);
  }
}
