import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action, DecisionDto, LogDto } from '@coin/importer/dto/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogsService } from '../../services/logs/logs.service';
import { ToastService } from '../../services/toast/toast.service';

type InitialData = {
  log: LogDto;
  action: Action;
};

@Component({
  selector: 'ci-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnDestroy {
  actionEnum = Action;
  public action: Action;
  public comment: string;
  public publish: boolean = true;
  public logDetails: LogDto;
  commentForm: UntypedFormGroup;
  isSubmitting = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InitialData,
    private logsService: LogsService,
    private toast: ToastService
  ) {
    this.logDetails = data.log;
    this.action = data.action;
  }

  close() {
    this.matDialogRef.close();
  }

  submit() {
    this.isSubmitting = true;
    const decision: Partial<DecisionDto> = {
      action: this.action,
      comment: this.comment,
      decidedAt: new Date(),
      publish: this.publish
    };
    this.logsService
      .sendDecision(this.logDetails.id, this.data.log.fileType.prefix, decision)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result: LogDto) => {
          if (result) {
            if (result.decision) {
              this.logDetails.decision = result.decision;
            }
            if (result.coinProcessingState) {
              this.logDetails.coinProcessingState = result.coinProcessingState;
            }
            this.toast.success('Your decision was successfully submitted.');
            this.matDialogRef.close(this.logDetails);
          } else {
            this.toast.error('Undefined Error - please try again later.');
            this.isSubmitting = false;
          }
        },
        () => {
          this.isSubmitting = false;
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
