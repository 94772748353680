import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EmailListEntryDto } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { SimpleTableHeader } from '../../simple-table/simple-table.component';
import { EmailListEntryPopupComponent } from '../email-list-entry-popup/email-list-entry-popup.component';
import { EmailListService } from '../../../services/organisation-management/email-list.service';

@Component({
  selector: 'ci-email_list',
  templateUrl: './email_list.component.html',
  styleUrls: ['./email_list.component.scss']
})
export class EmailListComponent {
  public queryParameters$: Observable<Params> = this.route.queryParamMap;
  public updaterIntervalInMs = null;
  public selectedTableHeaders: SimpleTableHeader[] = [
    {
      title: 'Edit',
      width: '50px'
    },
    {
      title: 'Delete',
      width: '50px'
    },
    {
      title: 'Gid',
      key: 'Gid',
      filterType: 'input'
    },
    {
      title: 'Email Address',
      key: 'EmailAddress',
      filterType: 'input'
    },
    {
      title: 'Created at',
      key: 'CreatedAt',
      filterType: 'date'
    },
    {
      title: 'Created by Gid',
      key: 'CreatedByGid',
      filterType: 'input'
    },
    {
      title: 'Last modified at',
      key: 'LastModifiedAt',
      filterType: 'date'
    },
    {
      title: 'Last modified by Gid',
      key: 'LastModifiedByGid',
      filterType: 'input'
    }
  ];

  constructor(
    public emailListService: EmailListService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  openCreateEmailListEntryPopup(): void {
    this.matDialog.open(EmailListEntryPopupComponent, {
      data: { action: PopupAction.New, item: {} }
    });
    error => {
      this.matDialog.closeAll();
    };
  }

  openUpdateEmailListEntryPopup(item: EmailListEntryDto): void {
    this.matDialog.open(EmailListEntryPopupComponent, {
      data: { action: PopupAction.Edit, item: item }
    });
    error => {
      this.matDialog.closeAll();
    };
  }

  openDeleteEmailListEntryPopup(item: EmailListEntryDto): void {
    this.matDialog.open(EmailListEntryPopupComponent, {
      data: {
        action: PopupAction.Delete,
        item: item
      }
    });
    error => {
      this.matDialog.closeAll();
    };
  }
}
