import { FileTypeDto } from '@coin/importer/dto/util';
import { Doughnut } from './doughnut';

export class FileTypeDoughnut extends Doughnut {
  constructor(data: FileTypeDto[]) {
    super(data);
  }

  protected fillData(input: FileTypeDto[]): void {
    for (let i = 0; i < input.length; i++) {
      const el = input[i];
      this.addEntry(el.numberOfLogs || 0, el.displayedName, this.colorPresets.error[i]);
    }
    this.addInnerPercentageOfTotal(null, null);
  }

  protected hasNoData(data: FileTypeDto[]): boolean {
    const haveAnyLogsAssigned = data?.filter(ft => ft.numberOfLogs && ft.numberOfLogs > 0)?.length;
    return !haveAnyLogsAssigned;
  }
}
