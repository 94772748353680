import { ValidationDto } from '@coin/importer/dto/util';
import { Doughnut } from './doughnut';

export class ValidationsDoughnut extends Doughnut {
  constructor(data: ValidationDto[]) {
    const displayedValidations = data?.filter(validation => typeof validation.sum === 'number' && validation.sum !== 0);
    super(displayedValidations);
  }

  protected fillData(input: ValidationDto[]): void {
    let passed = 0;
    let total = 0;
    let invalid = 0;

    const sorted = input.sort((a, b) => b.sum - a.sum);
    for (let i = 0; i < sorted.length; i++) {
      const element = sorted[i];
      if (element.validationName === 'pass') {
        sorted.splice(i, 1);
        sorted.splice(0, 0, element);
        break;
      }
    }

    for (let i = 0; i < input.length; i++) {
      const validation = input[i];
      total += validation.sum;

      let color: string;
      if (validation.validationName === 'pass') {
        passed += validation.sum;
        color = this.colorPresets.pass;
      } else {
        invalid += validation.sum;
        color = this.colorPresets.error[i];
      }

      this.addEntry(validation.sum, validation.validationName, color);
    }

    this.addInnerPercentageOfTotal([invalid, total, 'failed validations']);
  }

  protected hasNoData(data: ValidationDto[]): boolean {
    return !data?.length;
  }
}
