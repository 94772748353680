export enum AllocationFilterOptions {
  MeritPopulation = 'MeritPopulation',
  EquityPopulation = 'EquityPopulation',
  ManagementGroup = 'ManagementGroup',
  PositionClass = 'PositionClass',
  MercerJobCode = 'MercerJobCode',
  OrgCluster = 'OrgCluster',
  OrgClusterNode = 'OrgClusterNode',
  GeoCluster = 'GeoCluster',
  GeoClusterNode = 'GeoClusterNode',
  Country = 'Country',
  Gid = 'Gid',
  OrgCode = 'OrgCode',
  Workflow = 'Workflow',
  ContractStatus = 'ContractStatus',
  Are = 'Are'
}

export enum AllocationSortOptions {
  GeoCluster = 'GeoCluster',
  GeoClusterNode = 'GeoClusterNode',
  Gid = 'Gid',
  ContractStatus = 'ContractStatus'
}

export enum SortingType {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AllocationFilterOptionsDirectView {
  MeritPopulation = 'MeritPopulation',
  EquityPopulation = 'EquityPopulation',
  ManagementGroup = 'ManagementGroup',
  PositionClass = 'PositionClass',
  MercerJobCode = 'MercerJobCode',
  GeoCluster = 'GeoCluster',
  GeoClusterNode = 'GeoClusterNode',
  Country = 'Country',
  Gid = 'Gid',
  OrgCode = 'OrgCode',
  Workflow = 'Workflow',
  ContractStatus = 'ContractStatus',
  Are = 'Are'
}
