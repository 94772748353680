import { CoinTransactionState } from './coin-transaction-state.enum';

export class CoinTransactionStateUtils {
  static coinTransactionFinishedStates: CoinTransactionState[] = [CoinTransactionState.Finished, CoinTransactionState.Failed, CoinTransactionState.Cancelled];

  static processingCoinTransactionStates: CoinTransactionState[] = [CoinTransactionState.Started, CoinTransactionState.Running];

  static isFinishedCoinTransactionState = (coinTransactionState: CoinTransactionState): boolean => this.coinTransactionFinishedStates.includes(coinTransactionState);

  static isProcessingCoinTransactionState = (coinTransactionState: CoinTransactionState): boolean => this.processingCoinTransactionStates.includes(coinTransactionState);
}
