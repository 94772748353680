export enum State {
  Pending = 'Pending',
  Staging = 'Staging',
  Staged = 'Staged',
  Patching = 'Patching',
  Publishing = 'Publishing',
  Patched = 'Patched',
  Published = 'Published',
  Rejecting = 'Rejecting',
  Rejected = 'Rejected',
  Failed = 'Failed',
  CoinProcessing = 'CoinProcessing',
  CoinProcessed = 'CoinProcessed'
}
