import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateEmailListEntryDto, IPaginatedList, EmailListEntryDto, UpdateEmailListEntryDto } from '@coin/importer/dto/util';
import { environment } from '@coin/shared/util-environments';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaginatedResult } from '@coin/shared/util-models';
import { ToastService } from '../toast/toast.service';
import { AcceptedParams } from '../../components/simple-table/simple-table.component';

@Injectable({
  providedIn: 'root'
})
export class EmailListService {
  private newEntrySource$ = new ReplaySubject<EmailListEntryDto>(null);
  newEntry$ = this.newEntrySource$.asObservable();

  private tableResetter$ = new BehaviorSubject(false);
  resetter$ = this.tableResetter$.asObservable();

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public resetTable(value: boolean) {
    this.tableResetter$.next(value);
  }

  private api = [environment.api.importerService, 'organisation-management/email-list'].join('/');

  getEntries(params: Params): Observable<IPaginatedList<EmailListEntryDto>> {
    return this.http
      .get<IPaginatedList<EmailListEntryDto>>(this.api, {
        params
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  async createEntry(entry: CreateEmailListEntryDto) {
    return this.http
      .put(this.api, entry)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
  }

  async updateEntry(entry: UpdateEmailListEntryDto) {
    return this.http
      .patch(this.api, entry)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
  }

  async deleteEntry(gid: string) {
    return this.http
      .delete(`${this.api}/${gid}`)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
  }

  queryPagedAndFilteredData(params: AcceptedParams) {
    const sortedParams = this.sortParams(params);
    return this.getEntries(sortedParams).pipe(map(this.fitToPaginatedResult));
  }

  private fitToPaginatedResult(obj: IPaginatedList<EmailListEntryDto>): PaginatedResult<EmailListEntryDto> {
    const result: PaginatedResult<EmailListEntryDto> = {
      content: obj.items,
      currentPage: obj.meta.currentPage,
      nextPage: obj.meta.currentPage + 1,
      pageCount: obj.meta.totalPages,
      pageSize: obj.meta.itemsPerPage,
      total: obj.meta.totalItems
    };
    return result;
  }

  setTemporaryLog(element: EmailListEntryDto): void {
    this.newEntrySource$.next(element);
  }

  // ---

  // Following duplicate to LogsService,
  private sortParams(params: AcceptedParams): Params {
    const filteredParams: Params = {};
    let param: keyof typeof params;
    for (param in params) {
      const value = decodeURI(params[param] as string);
      switch (param) {
        case 'page': {
          const page = parseInt(value);
          if (page > 1) {
            // page=1 is default and should not show in url
            filteredParams[param] = page;
          }
          break;
        }
        case 'limit': {
          const limit = parseInt(value);
          if (limit > 0 && limit !== environment.importerTableLimit) {
            // limit=25 is default and should not show in url
            filteredParams[param] = limit;
          }
          break;
        }
        case 'search':
          if (value) {
            this.toast.info('Search-Parameter not yet implemented');
          }
          break;
        default:
          if (value.length > 0) {
            filteredParams[param] = value.includes('&') ? value.split('&') : value;
          }
          break;
      }
    }
    return filteredParams;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The Request could not be sent - please try again later');
    } else if (error.status === 404) {
      this.toast.errorWithRedirect(`The requested page could not be found.`, '404');
    } else {
      this.toast.error(`Error: "${error.error?.message || 'unknown'}"`);
    }
    return throwError(error);
  }
}
