import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { UiModule } from '@coin/importer/common/ui';
import { ToastService, TOAST_OPTIONS } from './services/toast/toast.service';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { AuthGmsClientConfig } from './auth/models/auth-gms-client.config';
import { AppState } from './auth/store';
import { ChartsComponent } from './components/charts/charts.component';
import { HeaderComponent } from './components/header/header.component';
import { ValidationListComponent } from './components/validation-list/validation-list.component';
import { DemoMaterialModule } from './material.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { DurationFromMsPipe } from './pipes/duration-from-ms/duration-from-ms.pipe';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { PercentagePipe } from './pipes/percentage/percentage.pipe';
import { LogsService } from './services/logs/logs.service';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FileNamePipe } from './pipes/file-name/file-name.pipe';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { ImportDetailsComponent } from './components/import-details/import-details.component';
import { ImportDecisionComponent } from './components/import-decision/import-decision.component';
import { PopupComponent } from './components/popup/popup.component';
import { ButtonFooterComponent } from './components/button-footer/button-footer.component';
import { UploadPopupComponent } from './components/upload-popup/upload-popup.component';
import { UploadService } from './services/upload/upload.service';
import { SettingsComponent } from './pages/settings/settings.component';
import { ExportAttributesPopupComponent } from './components/export-attributes-popup/export-attributes-popup.component';
import { ExportAttributesFormComponent } from './components/export-attributes-form/export-attributes-form.component';
import { FileTypePopupComponent } from './components/file-type-popup/file-type-popup.component';
import { ObjectRefresherPopupComponent } from './components/object-refresher-popup/object-refresher-popup.component';
import { FiletypeOverviewComponent } from './components/filetype-overview/filetype-overview.component';
import { SchemaValidationsPopupComponent } from './components/schema-validations-popup/schema-validations-popup.component';
import { TransactionStateProgressBarComponent } from './components/transaction-state-progress-bar/transaction-state-progress-bar.component';
import { SkipPreviousImportsPopupComponent } from './components/skip-previous-imports-popup/skip-previous-imports-popup.component';
import { RepublishComponent } from './pages/republish/republish.component';
import { RepublishSummaryComponent } from './pages/republish_summary/republish_summary.component';
import { TransferListComponent } from './components/organisation-management/transfer_list/transfer_list.component';
import { TransferListService } from './services/organisation-management/transfer-list.service';
import { OrganisationManagementComponent } from './pages/organisation-management/organisation_management.component';
import { StructureListService } from './services/organisation-management/structure-list.service';
import { StructureListComponent } from './components/organisation-management/structure_list/structure_list.component';
import { StructureListEntryPopupComponent } from './components/organisation-management/structure-list-entry-popup/structure-list-entry-popup.component';
import { TransferListEntryPopupComponent } from './components/organisation-management/transfer-list-entry-popup/transfer-list-entry-popup.component';
import { EmailListEntryPopupComponent } from './components/organisation-management/email-list-entry-popup/email-list-entry-popup.component';
import { EmailListComponent } from './components/organisation-management/email_list/email_list.component';
import { EmailListService } from './services/organisation-management/email-list.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    FormsModule,
    MatSliderModule,
    DemoMaterialModule,
    UiModule,
    SimplebarAngularModule,
    ToastrModule.forRoot(TOAST_OPTIONS),
    AuthGmsAngularClientModule.forRoot({ ...environment.authentication, ...environment.importerAuthentication } as AuthGmsClientConfig),
    NgxsModule.forRoot(AppState, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    BaseChartDirective
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    RepublishComponent,
    HeaderComponent,
    SummaryComponent,
    RepublishSummaryComponent,
    FileSizePipe,
    PercentagePipe,
    ChartsComponent,
    DurationFromMsPipe,
    ValidationListComponent,
    UserMenuComponent,
    FileNamePipe,
    SubHeaderComponent,
    SimpleTableComponent,
    ImportDetailsComponent,
    ImportDecisionComponent,
    PopupComponent,
    ButtonFooterComponent,
    UploadPopupComponent,
    SettingsComponent,
    ExportAttributesPopupComponent,
    ExportAttributesFormComponent,
    FileTypePopupComponent,
    ObjectRefresherPopupComponent,
    FiletypeOverviewComponent,
    SchemaValidationsPopupComponent,
    TransactionStateProgressBarComponent,
    SkipPreviousImportsPopupComponent,
    TransferListComponent,
    TransferListEntryPopupComponent,
    OrganisationManagementComponent,
    StructureListComponent,
    StructureListEntryPopupComponent,
    EmailListComponent,
    EmailListEntryPopupComponent
  ],
  exports: [RouterModule],
  providers: [LogsService, ToastService, UploadService, provideCharts(withDefaultRegisterables()), TransferListService, StructureListService, EmailListService],
  bootstrap: [AppComponent]
})
export class AppModule {}
