<div class="dropdown-container lib-dropdown">
  <span class="material-icons preicon" *ngIf="preIcon">{{ preIcon }}</span>

  <mat-form-field
    class="admin-form-field-outline admin-form-field-outline--shadow"
    [ngClass]="classAdditions"
    [appearance]="appearance"
    [floatLabel]="floatLabel"
    [class.small]="small"
    [class.tiny]="tiny"
    [class.readonly]="readonly"
  >
    <mat-label *ngIf="label">{{ label }}</mat-label>

    <mat-select
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="onOpenedChange($event)"
      [panelClass]="long ? 'lib-dropdown-long' : 'lib-dropdown'"
      [ngModel]="value"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [multiple]="multiple"
      #select
    >
      <mat-select-trigger
        *ngIf="
          (!displayFn && !multiple) ||
          (!displayFn && multiple && !value?.length)
        "
      >
        <p [class.need-bold]="isBold" *ngIf="!showSvgImages">
          {{ translateMe ? translateMe + value : value }}
        </p>

        <div *ngIf="showSvgImages" class="svg-images">
          <img
            [src]="'/assets/svgs/flags/' + value + '.svg'"
            [alt]="'Flag of ' + value"
            style="border-radius: 3px; border: 1px solid lightgrey"
          />
        </div>
      </mat-select-trigger>

      <mat-select-trigger *ngIf="multiple && value?.length > 1">
        <p>
          <i
            >Selected: <b>{{ value.length }}</b></i
          >
        </p>
      </mat-select-trigger>

      <div class="filter-container" *ngIf="withSearch">
        <div class="loading" *ngIf="loading">
          <app-turbine [withPole]="false" [shadow]="false"></app-turbine>
        </div>
        <mat-checkbox
          *ngIf="multiple && !selectAllDisabled && !showSortIndicator"
          class="check-all"
          (change)="selectAll($event.checked)"
        >
        </mat-checkbox>
        <div
          *ngIf="showSortIndicator"
          (click)="isAscSort = !isAscSort"
          class="sort-direction-indicator"
        >
          <mat-icon *ngIf="isAscSort">south</mat-icon>
          <mat-icon *ngIf="!isAscSort">north</mat-icon>
          <p class="sort-indicator">{{ isAscSort ? 'Asc' : 'Desc' }}</p>
        </div>
        <coin-input
          (input)="searchChanged(searchField.value)"
          label="Search"
          addClass="shadow"
          class="search-input"
          (keydown)="$event.stopPropagation()"
          #searchField
        ></coin-input>
      </div>

      <ng-container *ngIf="!lazyLoadFn; else lazyLoadedOptions">
        <mat-option
          [class.disabled]="isDisabled(itm)"
          *ngFor="let itm of filteredAndSortedItems"
          [value]="itm"
          [disabled]="isDisabled(itm)"
        >
          <p *ngIf="!showSvgImages" [class.need-bold]="isBold">
            {{ translateMe ? translateMe + getText(itm) : getText(itm) }}
          </p>
          <div
            *ngIf="showSvgImages"
            style="
              height: 20px;
              width: 30px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <img
              [src]="'/assets/svgs/flags/' + itm + '.svg'"
              [alt]="'Flag of ' + itm"
              style="border-radius: 3px"
            />
          </div>
        </mat-option>
      </ng-container>
      <ng-template #lazyLoadedOptions>
        <mat-option *ngFor="let itm of lazyLoadedItems$ | async" [value]="itm">
          <p *ngIf="!showSvgImages" [class.need-bold]="isBold">
            {{ translateMe ? translateMe + getText(itm) : getText(itm) }}
          </p>
          <div
            *ngIf="showSvgImages"
            style="
              height: 20px;
              width: 30px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <img
              [src]="'/assets/svgs/flags/' + itm + '.svg'"
              [alt]="'Flag of ' + itm"
              style="border-radius: 3px"
            />
          </div>
        </mat-option>
      </ng-template>

      <p
        style="padding: 10px 20px 20px"
        *ngIf="
          !lazyLoadFn
            ? !filteredAndSortedItems?.length
            : !(lazyLoadedItems$ | async)?.length
        "
      >
        - - - -
      </p>

      <div class="button-container" *ngIf="showApplyButton">
        <div class="inner-container">
          <coin-action-button class="primary" (click)="onPressApply()">{{
            bottomButtonText
          }}</coin-action-button>
        </div>
      </div>
    </mat-select>
  </mat-form-field>

  <span class="material-icons aftericon" *ngIf="afterIcon">{{
    afterIcon
  }}</span>
  <mat-icon
    class="remove"
    *ngIf="canDeselect && value"
    (click)="onPressRemove()"
    >cancel
  </mat-icon>
</div>
