export class ValidationColor {
  public readonly pass: string = '#009999';
  public readonly noCount: string = '#bcbcbc';
  public readonly unchanged: string = '#e5e5e5';
  public readonly error: string[] = [
    // '#361358',
    // '#41176b',
    // '#4c1b7e',
    '#5e328b',
    '#704998',
    '#825fa5',
    '#9476b2',
    '#a68dbe',
    '#b8a4cb',
    '#c9bbd8'
  ];

  public readonly placeholder: string = '';
}
