import { DestroyRef, inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Fn } from '@coin/shared/util-models';
import { Subscription, Observable, PartialObserver, takeUntil } from 'rxjs';

@Injectable()
// eslint:disable-next-line: component-class-suffix
export class SubscriptionHelper implements OnDestroy, LifecycleDependant {
  private subSink = new Subscription();
  private destroyRef = inject(DestroyRef);

  ngOnDestroy(): void {
    this.onDestroy();
  }

  onDestroy(): void {
    this.subSink.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public subscribe<T>(observable$: Observable<T>, observerOrNext?: PartialObserver<T> | ((value: T) => void), error?: (error: any) => void, complete?: () => void): Subscription {
    // TODO: rxjs 7 changed the add() return type due to being an antipattern, so this implementation might need reconsideration
    // https://github.com/ReactiveX/rxjs/blob/master/docs_app/content/6-to-7-change-summary.md

    const sub = observable$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: observerOrNext as (value: T) => void,
      error,
      complete
    });
    this.subSink.add(sub);
    return sub;
  }

  protected unsubscribe(innerSub: Subscription): void {
    if (innerSub) {
      this.subSink.remove(innerSub);
      innerSub.unsubscribe();
    }
  }
}

interface LifecycleDependant {
  onInit?: () => void;
  onDestroy: () => void;
}
