import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@coin/shared/util-environments';
import { BaseRepublishDto, IPaginatedList, ITransactionState, StateUtils, CompositeTransactionDto } from '@coin/importer/dto/util';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { PaginatedResult } from '@coin/shared/util-models';
import { ToastService } from '../toast/toast.service';
import { AcceptedParams, SimpleTableService } from '../../components/simple-table/simple-table.component';
import { FileTypeService } from '../file-type/file-type.service';

@Injectable({
  providedIn: 'root'
})
export class RepublishService implements SimpleTableService {
  private tableResetter$ = new BehaviorSubject(false);
  resetter$ = this.tableResetter$.asObservable();

  private newEntrySource$ = new ReplaySubject<BaseRepublishDto>(null);
  newEntry$ = this.newEntrySource$.asObservable();

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private fileTypeService: FileTypeService
  ) {}

  getSingleImportLog(id: string): Observable<BaseRepublishDto> {
    return this.http.get<BaseRepublishDto>(`${environment.api.importerService}/republish/${id}`).pipe(catchError(this.handleError.bind(this)));
  }

  public resetTable(value: boolean) {
    this.tableResetter$.next(value);
  }

  queryPagedAndFilteredData(params: AcceptedParams) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });
    return this.getImportLogs(params).pipe(map(this.fitToPaginatedResult));
  }

  getImportLogs(params: Params): Observable<IPaginatedList<BaseRepublishDto>> {
    return this.http
      .get<IPaginatedList<BaseRepublishDto>>(`${environment.api.importerService}/republish`, {
        params
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  private fitToPaginatedResult(obj: IPaginatedList<BaseRepublishDto>): PaginatedResult<BaseRepublishDto> {
    const result: PaginatedResult<BaseRepublishDto> = {
      content: obj.items,
      currentPage: obj.meta.currentPage,
      nextPage: obj.meta.currentPage + 1,
      pageCount: obj.meta.totalPages,
      pageSize: obj.meta.itemsPerPage,
      total: obj.meta.totalItems
    };
    return result;
  }

  setTemporaryLog(element: BaseRepublishDto): void {
    this.newEntrySource$.next(element);
  }

  checkProgressUpdate(ids: Partial<BaseRepublishDto>[]): Observable<BaseRepublishDto[]> {
    return this.http.post<BaseRepublishDto[]>(`${environment.api.importerService}/reloadRepublishLogs`, ids).pipe(tap(this.handleFinalProgressStep.bind(this)));
  }

  // TODO
  private handleFinalProgressStep(logs: BaseRepublishDto[]) {
    logs.forEach(log => {
      if (StateUtils.isSuccessfullyFinishedLogState(log.state)) {
        this.fileTypeService.loadFileTypeToSubject(log.fileType.id);
      }
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The Request could not be sent - please try again later');
    } else if (error.status === 404) {
      this.toast.errorWithRedirect(`The requested page could not be found.`, '404');
    } else {
      this.toast.error(`Error: "${error.error?.message || 'unknown'}"`);
    }
    return throwError(error);
  }

  // TODO duplicate
  getCompositeTransactionById(id: string): Observable<CompositeTransactionDto> {
    const url = `${environment.api.importerService}/${id}/compositeTransaction`;
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }
  // TODO duplicate
  getTransactionStatesById(id: string): Observable<ITransactionState> {
    const url = `${environment.api.importerService}/${id}/coinTransactionState`;
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }
}
