import { ChartData, ChartOptions, ChartType, Color } from 'chart.js';
import { ValidationColor } from './validation-colors';

export type InnerPercentage = [number, number, string];

export abstract class Doughnut {
  colorPresets = new ValidationColor();
  noData = false;
  labels: string[] = [];
  dataPoints = [];
  data: ChartData<'doughnut'> = {
    labels: this.labels,
    datasets: []
  };
  colors: Color[] = [];
  options: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      emptyDoughnut: {
        color: '#e5e5e5',
        width: 35
      },
      legend: { display: false },
      tooltip: {
        enabled: true,
        bodyColor: '#fff'
      }
    }
  };
  type: ChartType = 'doughnut';
  first: InnerPercentage;
  second: InnerPercentage;

  constructor(data: unknown) {
    if (this.hasNoData(data)) {
      this.setNoData();
    } else {
      this.fillData(data);
    }
  }

  setNoData(): void {
    this.data.datasets = [];
    this.options.plugins.tooltip.enabled = false;
    this.noData = true;
  }

  addEntry(dataPoint: number, label: string, color?: string): void {
    this.dataPoints.push(dataPoint);
    this.labels.push(label);
    if (Array.isArray(this.colors) && color) {
      this.colors.push(color);
    }

    this.data.datasets = [
      {
        label,
        backgroundColor: this.colors,
        data: this.dataPoints
      }
    ];
  }

  addInnerPercentageOfTotal(first?: InnerPercentage, second?: InnerPercentage): void {
    this.first = first;
    this.second = second;
  }

  protected abstract hasNoData(data: unknown): boolean;

  protected abstract fillData(input: unknown): void;
}
