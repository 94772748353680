<div class="simple-table-container">
  <div class="search-container" *ngIf="withSearch">
    <coin-input
      svgIcon="search"
      [placeholder]="'search'"
      addClass="shadow"
      [type]="'text'"
      (input)="onSearchChange(input.value)"
      #input
    ></coin-input>
  </div>

  <div class="simple-table">
    <div
      class="header-row template-columns"
      [style.grid-template-columns]="templateColumns"
    >
      <div
        class="header-column"
        [class.with-controls]="item.key"
        [class.overlay]="item.overlay"
        [class.overlay--start]="item.overlayStart"
        [class.overlay--end]="item.overlayEnd"
        [class.overlay--contrast]="item.overlayContrast"
        [class.inactive]="isItemInactive(item.group)"
        [class.hidden]="isItemInactive(item.group) && !isGroupStart(item, i)"
        style="flex-wrap: wrap"
        *ngFor="let item of headerObjects; let i = index"
      >
        <div
          *ngIf="item.group"
          class="group"
          (click)="toggleInactive(item.group)"
          [class.start]="isGroupStart(item, i)"
          [class.inactive]="isItemInactive(item.group)"
          [class.end]="isGroupEnd(item, i)"
          [class.end-radius]="isGroupEnd(item, i) || isItemInactive(item.group)"
        >
          <div *ngIf="isGroupStart(item, i)" class="group-title">
            <p class="bold">{{ item.group }}</p>
          </div>
          <mat-icon *ngIf="isGroupEnd(item, i)">visibility_on</mat-icon>
          <mat-icon *ngIf="isGroupStart(item, i) && isItemInactive(item.group)"
            >visibility_off</mat-icon
          >
        </div>

        <div class="header">
          <div class="filter-controls" *ngIf="item.key && !item.hideFilter">
            <mat-icon
              class="clear-filter"
              *ngIf="filterActive(item.key)"
              (click)="clearFilterByKey(item.key)"
              >clear
            </mat-icon>
            <span
              (click)="showFilterOptions(item)"
              *ngIf="item.filterType"
              class="expand-mat-icon st-filter"
              [class.st-filter--active]="filterActive(item.key)"
              [class.no-sorting]="item.hideSorting"
              [matTooltip]="item.filterTooltip"
            >
              <img
                aria-label="filter"
                style="width: 18px; height: 18px"
                src="/assets/svgs/filter.svg"
              />
            </span>

            <div class="sort-controls" *ngIf="!item.hideSorting">
              <div
                (click)="sortBy(item)"
                class="sort-button"
                [ngClass]="{
                  active:
                    sortedItemsBy['type'] === item.key &&
                    sortedItemsBy['direction'] === 'DESC'
                }"
              >
                <mat-icon class="expand-mat-icon icon-less"
                  >expand_less</mat-icon
                >
              </div>
              <div
                (click)="sortBy(item)"
                class="sort-button"
                [ngClass]="{
                  active:
                    sortedItemsBy['type'] === item.key &&
                    sortedItemsBy['direction'] === 'ASC'
                }"
              >
                <mat-icon class="expand-mat-icon icon-more"
                  >expand_more</mat-icon
                >
              </div>
            </div>
          </div>

          <div *ngIf="item.hide" class="spacer"></div>

          <p
            *ngIf="!item.hide"
            class="header-title"
            (click)="showFilterOptions(item)"
          >
            {{ item.title }}
          </p>
          <coin-checkbox
            *ngIf="item.checkAll"
            class="check-all"
            (onToggle)="checkAllToggle($event)"
            [addClass]="'white'"
            [size]="20"
            matTooltip="Select all visible items"
          >
          </coin-checkbox>

          <div
            @shrinkExpandAll
            *ngIf="showFilter === item.key"
            class="sub-header"
          >
            <mat-form-field
              *ngIf="item.filterType === 'date'"
              class="simple-table-date"
            >
              <input
                (dateChange)="applyFilter()"
                aria-label="date input"
                (focus)="pickerCreatedFrom.open()"
                [ngModel]="
                  filterVariables[item.key + '---' + 'From'] &&
                  filterVariables[item.key + '---' + 'From'][0]
                "
                (ngModelChange)="
                  filterVariables[item.key + '---' + 'From'] = [$event]
                "
                [max]="
                  (filterVariables[item.key + '---' + 'To'] &&
                    filterVariables[item.key + '---' + 'To'][0]) ||
                  today
                "
                matInput
                [matDatepicker]="pickerCreatedFrom"
                placeholder="From"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerCreatedFrom"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerCreatedFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field
              *ngIf="item.filterType === 'date'"
              class="simple-table-date date-picker"
            >
              <input
                (dateChange)="applyFilter()"
                aria-label="date input"
                (focus)="pickerCreatedTo.open()"
                [ngModel]="
                  filterVariables[item.key + '---' + 'To'] &&
                  filterVariables[item.key + '---' + 'To'][0]
                "
                (ngModelChange)="
                  filterVariables[item.key + '---' + 'To'] = [$event]
                "
                [min]="
                  filterVariables[item.key + '---' + 'From'] &&
                  filterVariables[item.key + '---' + 'From'][0]
                "
                [max]="today"
                matInput
                [matDatepicker]="pickerCreatedTo"
                placeholder="To"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerCreatedTo"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerCreatedTo></mat-datepicker>
            </mat-form-field>
            <coin-dropdown
              *ngIf="
                (item.filterType === 'dropdown' ||
                  item.filterType === 'customDropdown') &&
                item?.async
              "
              [lazyLoadFn]="lazyLoadFn"
              [searchTarget]="item.key"
              [(ngModel)]="filterVariables[item.key]"
              [withSearch]="true"
              [multiple]="true"
              [selectAllDisabled]="true"
              (selectionChange)="applyFilter()"
              [small]="true"
            >
            </coin-dropdown>
            <coin-dropdown
              *ngIf="item.filterType === 'dropdown' && !item?.async"
              [items]="getDropdownVals(item)"
              [(ngModel)]="filterVariables[item.key]"
              [withSearch]="true"
              [multiple]="true"
              [selectAllDisabled]="true"
              (selectionChange)="applyFilter()"
              [small]="true"
              [sort]="false"
            ></coin-dropdown>

            <mat-form-field
              *ngIf="item.filterType === 'input'"
              class="simple-table-date"
            >
              <mat-label>Please enter</mat-label>
              <input
                aria-label="normal input"
                matInput
                [(ngModel)]="filterVariables[item.key]"
                (input)="applyFilter()"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <ngx-simplebar
      class="content-container primary-scrollbar-vertical simple-table no-space"
    >
      <ng-container *ngIf="dragAndDrop; else default">
        <div
          class="drop-list"
          cdkDropList
          (cdkDropListDropped)="onDrop($event)"
          [cdkDropListSortPredicate]="sortPredicate.bind(this)"
        >
          <div
            *ngFor="let item of data; let i = index; trackBy: trackByItemFn"
            class="content-row template-columns"
            [style.grid-template-columns]="
              !item.isSeparator ? templateColumns : 'unset'
            "
            cdkDrag
            [cdkDragDisabled]="item.isDragDisabled"
            [class.drag-disabled]="item.isDragDisabled"
            [cdkDragData]="item"
            cdkDragBoundary=".drop-list"
            [class.separator-container]="item.isSeparator"
            @shrinkExpand
          >
            <ng-template
              [ngTemplateOutlet]="columnsRef"
              [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            >
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #default>
        <div
          *ngFor="let item of data; let i = index; trackBy: trackByItemFn"
          class="content-row template-columns"
          [style.grid-template-columns]="
            !item.isSeparator ? templateColumns : 'unset'
          "
          [class.separator-container]="item.isSeparator"
        >
          <!-- [tableGroup]="inactiveColumns" -->
          <ng-template
            [ngTemplateOutlet]="columnsRef"
            [ngTemplateOutletContext]="{ $implicit: item, index: i }"
          >
          </ng-template>
        </div>
      </ng-template>
    </ngx-simplebar>

    <div class="loading-no-data-container" *ngIf="dataAvailable && loading">
      <app-turbine [contrast]="true"></app-turbine>
    </div>
    <div *ngIf="!dataAvailable" class="loading-no-data-container">
      <ng-template
        *ngIf="!loading && !data?.length && noDataRef"
        [ngTemplateOutlet]="noDataRef"
      ></ng-template>
      <app-turbine *ngIf="loading"></app-turbine>
    </div>

    <div class="action-bar">
      <div class="button-container" *ngIf="buttonsRef">
        <ng-template [ngTemplateOutlet]="buttonsRef"></ng-template>
      </div>

      <div class="paginator" *ngIf="!hidePagination">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageChange($event)"
          #paginator
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
