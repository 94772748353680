<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>Create validation rules for {{ fileTypeName }}</h3>
    </div>

    <div class="box">
      <div class="group">
        <p>
          The following list represents the fixed schema of the file type. For
          each attribute a validation rule can be defined. The rules need to be
          <span>passed</span> - otherwise they will be listed as violations for
          each upload.
        </p>
        <div class="flex-container">
          <ci-simple-table
            class="mat-elevation-z4"
            [externalDataList]="schema"
            [header]="schemaHeader"
            [hidePagination]="true"
            [loading]="loading"
          >
            <ng-template #columns let-item>
              <div class="content-column">
                <p>
                  {{ item.attribute }}
                </p>
              </div>
              <div class="content-column">
                <p>
                  {{ item.type }}
                </p>
              </div>
              <div class="content-column">
                <coin-dropdown
                  [items]="getOperatorList(item)"
                  [(ngModel)]="item.operator"
                  [shadow]="true"
                  [canDeselect]="true"
                  (pressApplyChanges)="onDropdownChange(item)"
                  [disabled]="!getOperatorList(item).length"
                  style="padding-bottom: 0px !important"
                >
                </coin-dropdown>
              </div>
              <div class="content-column">
                <coin-input
                  type="text"
                  placeholder="value"
                  [(ngModel)]="item.compareValue"
                  [disabled]="disableCompareValue(item)"
                ></coin-input>
                <p *ngIf="missingCompareValue(item)" class="missing-text">
                  a value must be entered
                </p>
                <p *ngIf="invalidParseableNumber(item)" class="missing-text">
                  value must be a valid number
                </p>
              </div>
              <div class="content-column">
                <coin-input
                  type="text"
                  placeholder="name of custom validation"
                  [disabled]="!item.operator"
                  [(ngModel)]="item.validationName"
                ></coin-input>
                <p *ngIf="invalidValidationName(item)" class="missing-text">
                  a value must be entered
                </p>
              </div>
            </ng-template>
            <ng-template #noData>
              <p>no data</p>
            </ng-template>
          </ci-simple-table>
        </div>

        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            Cancel
          </coin-action-button>
          <coin-action-button
            class="primary"
            svgIcon="upload"
            (click)="save()"
            [disabled]="loading || hasInputErrors()"
          >
            Save
          </coin-action-button>
        </div>
      </div>
    </div>
  </div>
</div>
