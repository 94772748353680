import { UserAuthReq } from '../models';

export class LoadUser {
  static readonly type = '[User] Load User';
}

export class LoadLoggedInUser {
  static readonly type = '[User] Load Logged In User';
}

export class LoadLoggedInUserSuccess {
  static readonly type = '[User] Load Logged In User Success';
  constructor(public readonly payload: UserAuthReq) {}
}

export class LoadUserSuccess {
  static readonly type = '[User] Load User Success';
  constructor(public readonly payload: UserAuthReq) {}
}
