import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mgTranslation' })
export class MgmtGroupTranslationPipe implements PipeTransform {
  private mgTranslation = {
    A: 'Managing Board',
    B: 'Top Management',
    C: 'Senior Management',
    O: 'Non-Senior-Management',
    Z: 'default'
  };
  transform(managementGroup: string): string {
    if (!managementGroup) {
      return '';
    }

    return this.mgTranslation[managementGroup] || '';
  }
}
