<div
  class="button-container"
  [class.no-horizontal-padding]="noHorizontalPadding"
>
  <div class="buttons-left">
    <ng-container *ngTemplateOutlet="buttonsLeftTemplate"> </ng-container>
  </div>
  <div class="buttons-center">
    <ng-container *ngTemplateOutlet="buttonsCenterTemplate"> </ng-container>
  </div>
  <div class="buttons-right">
    <ng-container *ngTemplateOutlet="buttonsRightTemplate"> </ng-container>
  </div>
</div>
