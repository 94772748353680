<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>Please confirm...</h3>
    </div>
    <div class="box">
      <div class="group">
        <p>
          Are you sure you sure you want to
          <span>{{ action | uppercase }}</span> the following file:
        </p>
        <div>
          <p>File Name:</p>
        </div>
        <div>
          <input matInput readonly [value]="logDetails.fileName | fileName" />
        </div>
        <div>
          <p>File Type:</p>
        </div>
        <div>
          <input matInput readonly [value]="logDetails.fileType.displayedName" />
        </div>
        <div>
          <p>Datasets <strong>passing</strong> validation:</p>
        </div>
        <div>
          <input matInput readonly [value]="logDetails.stats.changedDatasets - logDetails.stats.invalidDatasets | number: '1.0'" />
        </div>
        <div></div>
        <div>
          <input
            matInput
            readonly
            [value]="logDetails.stats.changedDatasets - logDetails.stats.invalidDatasets | percentage: logDetails.stats.totalDatasets" />
        </div>

        <div *ngIf="action === actionEnum.Accept"></div>
        <div *ngIf="action === actionEnum.Accept">
          <mat-checkbox [(ngModel)]="publish">Publish to Kafka</mat-checkbox>
        </div>
      </div>

      <mat-divider></mat-divider>
      <form class="form">
        <mat-form-field appearance="fill" style="width: 96%">
          <mat-label>{{ action === actionEnum.Reject ? 'Please provide reason for rejection (mandatory)' : 'Please provide a comment (optional)' }}</mat-label>
          <textarea
            matInput
            cols="90"
            rows="3"
            #message
            maxlength="200"
            [(ngModel)]="comment"
            #txt="ngModel"
            class="form-control"
            [required]="action === actionEnum.Reject"
            name="message"></textarea>
          <mat-hint align="end">{{ message.value.length }} / 200</mat-hint>
        </mat-form-field>
      </form>
      <mat-divider></mat-divider>
      <div class="buttons">
        <coin-action-button class="secondary" svgIcon="cancel" (click)="close()"> Cancel </coin-action-button>
        <coin-action-button class="primary" (click)="submit()" svgIcon="save" [disabled]="txt.invalid || isSubmitting">
          {{ action }}
        </coin-action-button>
      </div>
    </div>
  </div>
</div>
