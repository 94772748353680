<div class="custom-header">
  <div class="left">
    <div class="mod-logo">
      <div class="logo-wrapper" (click)="gotoStartPage()"></div>
      <div class="environment" *ngIf="currentEnvironment">
        <mat-icon *ngIf="currentEnvironment?.icon">{{
          currentEnvironment.icon
        }}</mat-icon>
        <p *ngIf="currentEnvironment?.icon" class="bold">
          {{ currentEnvironment.type }}
        </p>
      </div>
    </div>
  </div>
  <ci-user-menu></ci-user-menu>
  <div class="right"></div>
</div>
