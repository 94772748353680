<div class="group">
  <div>
    <p>Number of validates Changes:</p>
    <input matInput readonly [value]="(totalChanges | number: '1.0') || 'n/a'" />
  </div>
  <div>
    <p>Changes passing Validation:</p>
    <input matInput readonly [value]="(passed | number: '1.0') || 'n/a'" />
  </div>
  <div></div>
  <div>
    <p>Changes with Validation errors:</p>
    <input matInput readonly [value]="(failed | number: '1.0') || 'n/a'" />
  </div>
  <div></div>
</div>
<mat-divider></mat-divider>

<ci-simple-table style="max-height: 250px" class="mat-elevation-z4" [externalDataList]="dataSource" [header]="validationsHeader" [hidePagination]="true">
  <ng-template #columns let-item>
    <div class="content-column">
      <p>
        {{ item.validationName || 'n/a' }}
      </p>
    </div>
    <div class="content-column">
      <p>
        {{ item.sum | number: '1.0' }}
      </p>
    </div>
    <div class="content-column">
      <p>
        {{ item.sum | percentage: totalChanges }}
      </p>
    </div>
  </ng-template>
  <ng-template #noData>
    <p>no validation rules were applied</p>
  </ng-template>
</ci-simple-table>
