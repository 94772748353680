<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>Upload</h3>
    </div>

    <div class="box">
      <div class="loading-container" *ngIf="loading">
        <app-loading-screen
          [local]="true"
          [contrast]="true"
        ></app-loading-screen>
      </div>
      <!-- <p>Please pick a file</p> -->
      <div class="group" *ngIf="!loading">
        <div class="flex-container">
          <coin-dropdown
            *ngIf="entities.length"
            [items]="entities"
            [shadow]="true"
            style="margin-left: 20px"
            label="File Type"
            [disableOptionFn]="disableOption"
            [disableCommentFn]="disableComment"
            [displayFn]="dropdownDisplayFn"
            (selectionChange)="applySelectionChange($event)"
            [disabled]="uploading"
          >
          </coin-dropdown>
        </div>
        <div class="flex-container">
          <coin-input
            *ngIf="importFileName"
            label="Selected File"
            style="margin-left: 20px"
            matInput
            [disabled]="true"
            [type]="'text'"
            [(ngModel)]="importFileName"
          ></coin-input>
          <ng-template #buttonsRightTemplate>
            <coin-action-button
              mat-raised-button
              ngClass="primary"
              icon="select File"
              (click)="fileInput.click()"
              [disabled]="!selectedFileTypePrefix || uploading"
            >
              {{ importFileName ? 'Change File' : 'Select File' }}
              <input
                #fileInput
                type="file"
                (change)="onFileSelected($event)"
                style="display: none"
              />
            </coin-action-button>
          </ng-template>
          <ci-button-footer
            [buttonsRightTemplate]="buttonsRightTemplate"
            style="margin: 0"
          >
          </ci-button-footer>
        </div>

        <div class="group" *ngIf="importFileName">
          <p class="flex-container">
            <coin-checkbox
              (onToggle)="toggleFullLoad($event)"
              [checked]="fullLoadCheckBoxState"
              matTooltip="On full load all objects which are not included in upload will be collected for manual delete from current base"
            ></coin-checkbox>
            Full Load
          </p>
        </div>

        <mat-progress-bar
          [color]="progressBarColor"
          [mode]="progressMode"
          [value]="progressValue"
          *ngIf="!loading"
        >
        </mat-progress-bar>
        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            {{ uploading ? 'Cancel' : 'Close' }}
          </coin-action-button>
          <coin-action-button
            class="primary"
            svgIcon="upload"
            [disabled]="!importFile || !selectedFileTypePrefix || uploading"
            (click)="save()"
          >
            {{ uploading ? 'Uploading' : 'Upload' }}
          </coin-action-button>
        </div>
      </div>
    </div>
  </div>
</div>
