import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';

@Injectable()
export class UnauthorizedInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private toast: ToastService,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        if (err.status === 403 && !err.url.startsWith('https://hjscu7ybp5')) {
          if (!this.router.routerState.snapshot.url) {
            this.ngZone.run(() => this.router.navigate(['unauthorized']));
          } else {
            this.ngZone.run(() => this.toast.errorWithRedirect('Not authorized.', 'unauthorized'));
          }
          return of(err);
        }
        return throwError(err);
      })
    );
  }
}
