<div class="container">
  <h4 *ngIf="data.headline" class="top-header">
    {{ data.translate ? data.headline : data.headline }} {{ getHeadlineData() }}
  </h4>
  <div style="padding: 2em">
    <h4 *ngIf="!data.headline">
      {{ data.translate ? getTranslatedValue(data.msg) : data.msg }}
    </h4>
    <p *ngIf="data.headline && data.msg" style="margin-bottom: 20px">
      {{ data.translate ? getTranslatedValue(data.msg) : data.msg }}
    </p>
    <coin-dropdown
      *ngIf="data?.dropdownValues?.length"
      [label]="data?.dropdownLabel"
      [(ngModel)]="selectedDropdownValue"
      [items]="data.dropdownValues"
      [shadow]="true"
      [displayFn]="dropdownDisplayFn"
    >
    </coin-dropdown>
    <div class="comment-text" *ngIf="data?.provideInput">
      <input
        title="text"
        name="text"
        id="text"
        [(ngModel)]="providedInput"
        placeholder="Enter comment..."
      />
    </div>
    <div class="buttons">
      <coin-action-button (click)="cancel()" *ngIf="data.cancelMsg">
        {{
          data.translate ? data.cancelMsg : data.cancelMsg
        }}</coin-action-button
      >
      <coin-action-button
        class="primary"
        (click)="confirm()"
        [disabled]="data?.inputMandatory ? !providedInput?.trim() : false"
      >
        {{ data.translate ? data.confirmMsg : data.confirmMsg }}
      </coin-action-button>
    </div>
  </div>
</div>
