import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; msg: string },
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
  ) {}

  proceed(type) {
    this.dialogRef.close(type);
  }

  goBack() {
    this.dialogRef.close();
  }
}
