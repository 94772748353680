import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadLevel } from '@coin/importer/dto/util';
import { environment } from '@coin/shared/util-environments';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastService
  ) {}

  uploadViaPresignedUrl(prefix: string, file: File, loadLevel: LoadLevel): Observable<HttpEvent<unknown>> {
    return this.httpClient
      .get<{
        signedUrl: string;
      }>(`${environment.api.importerService}/uploadUrl?prefix=${prefix}&fileName=${encodeURIComponent(file.name)}&loadLevel=${loadLevel}`)
      .pipe(
        switchMap(({ signedUrl }) => {
          return this.httpClient.put<HttpEvent<unknown>>(signedUrl, file, {
            headers: {
              'Content-Type': file.type
            },
            reportProgress: true,
            observe: 'events'
          });
        }),
        catchError(this.handleError.bind(this))
      );
  }

  uploadSchemaSourceFileViaPresignedUrl(prefix: string, file: File): Observable<HttpEvent<unknown>> {
    return this.httpClient
      .get<{
        signedUrl: string;
      }>(`${environment.api.importerService}/schemaSourceFileUrl?prefix=${prefix}&fileName=${encodeURIComponent(file.name)}`)
      .pipe(
        switchMap(({ signedUrl }) => {
          return this.httpClient.put<HttpEvent<unknown>>(signedUrl, file, {
            headers: {
              'Content-Type': file.type
            },
            reportProgress: true,
            observe: 'events'
          });
        }),
        catchError(this.handleError.bind(this))
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The File could not be sent - please try again later');
    } else {
      this.toast.error(`Error trying to upload data: ${error}`);
    }
    return throwError(error);
  }
}
