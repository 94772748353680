import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, LogDto, State } from '@coin/importer/dto/util';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { LogsService } from '../../services/logs/logs.service';
import { PopupComponent } from '../../components/popup/popup.component';

@Component({
  selector: 'ci-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  public data: LogDto;
  public isLoading: boolean;
  public actionEnum = Action;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private logsService: LogsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(params => this.logsService.getSingleImportLog(params.id)),
        takeUntil(this.destroy$)
      )
      .subscribe(obj => {
        this.data = obj;
        this.isLoading = false;
      });
  }

  formatErrorMessage(input: string | undefined): string {
    if (!input || typeof input !== 'string') return '';
    return input.split('jobRunId')[0].trim();
  }

  openPopup(action: Action): void {
    const popup = this.matDialog.open(PopupComponent, {
      disableClose: true,
      data: {
        log: this.data,
        action
      }
    });
    popup.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(this.popupResponse.bind(this));
  }

  private popupResponse(response?: LogDto) {
    if (response) {
      if (response.decision) {
        this.data.decision = response.decision;
        this.data.state = response.decision.action === Action.Reject ? State.Rejecting : response.decision.publish ? State.Publishing : State.Patching;
      }
      if (response.coinProcessingState) {
        this.data.coinProcessingState = response.coinProcessingState;
      }
    }
  }

  goBack() {
    this.router.navigate(['']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
