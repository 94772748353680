import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance, FloatLabelType } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Fn } from '@coin/shared/util-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { SortingType } from '../../enums/filter-sort.enum';
import { PaginatedResult } from '../../models/paginated-result.model';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'coin-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder;
  @Input() translateMe: string = null;

  @Input() items = [];
  @Input() formControlName: string;
  @Input() preselect;

  @Input() addClass;
  @Input() preIcon;
  @Input() afterIcon;
  @Input() isBold = false;
  @Input() noMargin: boolean;
  @Input() shadow: boolean;
  @Input() showSvgImages = false;
  @Input() readonly = false;
  @Input() canDeselect = false;
  @Input() disableOptionFn: (arg0: unknown) => boolean;
  @Input() disableCommentFn: (arg0?: unknown) => string;

  @Input() disabled: boolean;
  @Input() bottomButtonText: string;
  @Input() small?: boolean;
  @Input() long?: boolean;
  @Input() tiny?: boolean;
  @Input() selectAllDisabled: boolean;
  @Input() showSortIndicator: boolean;
  @Input() showApplyButton: boolean;
  @Input() multiple = false;
  @Input() singleCheckboxSelect = false;
  @Input() withSearch: boolean;
  @Input() sort = true;
  @Input() sortSelectedFirst = false;
  @Input() displayFn: (arg0: unknown) => string;

  @Input() searchTarget: string;
  @Input() lazyLoadFn: (page: number, search: string, key: string) => Observable<PaginatedResult<string>>;

  @Input() reloadItems$: Subject<void>;

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<MatSelectChange>(); // TODO this can be remove and the change event can be used
  @Output() pressApplyChanges = new EventEmitter();

  @ViewChild(MatSelect, { static: false }) select: MatSelect;
  @ViewChild('searchField', { static: false }) searchField: InputComponent;

  public search = '';
  public lazyLoadedItems$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public loading = false;
  public classAdditions = {};
  public isAscSort = true;

  private searchChanged$ = new Subject<void>();
  private unsubscribe$ = new Subject();
  private page = 0;
  private hasAllFieldValues = false;
  private previousSelection = [];

  get filteredAndSortedItems() {
    if (!this.items) {
      return [];
    }

    const sorted = this.sort ? this.sortItems(this.items) : this.items;
    return sorted.filter(item => this.getText(item).toLowerCase()?.includes(this.search.toLowerCase()));
  }

  get appearance(): MatFormFieldAppearance {
    return 'outline';
  }

  get floatLabel(): FloatLabelType {
    return 'always';
  }

  private _value: unknown = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }
  get value() {
    return this._value;
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  private destroy$: Subject<void> = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.classAdditions = {
      [`coin-form-field-outline--${this.addClass}`]: this.addClass,
      'coin-form-field-outline--preIcon': this.preIcon,
      'coin-form-field-outline--shadow': this.shadow,
      'no-margin': this.noMargin
    };

    this.initLazyLoading();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initLazyLoading() {
    if (this.lazyLoadFn) {
      this.subscribeToSearchChanged();
      this.searchChanged$.next();
    }

    if (this.reloadItems$) {
      this.reloadItems$.pipe(takeUntil(this.destroy$)).subscribe(() => this.searchChanged$.next());
    }
  }

  private async subscribeToSearchChanged() {
    this.searchChanged$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {
      this.page = 0;
      this.loading = true;
      this.lazyLoadFn(this.page, this.search, this.searchTarget)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
          takeUntil(this.destroy$) // TODO ???
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe(result => {
          let items: string[] = [];
          this.hasAllFieldValues = result?.pageCount === result?.currentPage;
          const currentValues = Array.isArray(this.value) ? [...this.value] : [this.value];
          if (this.value) {
            items = [...new Set([...currentValues, ...result?.content])];
          } else {
            items = result?.content;
          }
          this.lazyLoadedItems$.next(items);
        });
    });
  }

  private scrollEventListener = async event => {
    const percentage = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight;

    if (percentage && !this.hasAllFieldValues) {
      // load more items
      const currentItems = this.lazyLoadedItems$.getValue();

      this.page++;
      this.loading = true;

      this.lazyLoadFn(this.page, this.search, this.searchTarget)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
          takeUntil(this.destroy$) // TODO ???
        )
        .subscribe(result => {
          this.hasAllFieldValues = result?.pageCount === result.currentPage;
          this.lazyLoadedItems$.next([...new Set([...currentItems, ...result.content])]);
        });
    }
  };

  private sortItems(items: string[]) {
    const selected = this.previousSelection;
    return [...items].sort((a, b) => {
      if (this.multiple && this.sortSelectedFirst && selected?.length) {
        const aSel = selected.includes(a);
        const bSel = selected.includes(b);
        if (aSel && !bSel) {
          return -1;
        }
        if (!aSel && bSel) {
          return 1;
        }
      }
      return this.getText(a).localeCompare(this.getText(b));
    });
  }

  async searchChanged(value: string) {
    this.search = value;
    this.searchChanged$.next();
  }

  getText(item: unknown): string {
    let finalText = this.displayFn ? this.displayFn(item) : item.toString();
    if (this.isDisabled(item) && this.disableCommentFn) {
      finalText += this.disableCommentFn(item);
    }
    return finalText;
  }

  isDisabled(item: unknown): boolean {
    if (this.disableOptionFn) {
      return this.disableOptionFn(item);
    }
    return false;
  }

  selectAll(checked) {
    this.select.options.forEach(option => (checked ? option.select() : option.deselect()));
  }

  public open() {
    this.select.open();
  }

  onOpenedChange(opened: boolean) {
    this.openedChange.emit(opened);
    this.previousSelection = this.value;

    if (!opened || !this.withSearch) {
      if (this.searchField) {
        this.search = '';
        this.searchField.clear();
      }
      return;
    }

    if (opened && this.lazyLoadFn) {
      this.select.panel.nativeElement.removeEventListener('scroll', this.scrollEventListener);
      this.select.panel.nativeElement.addEventListener('scroll', this.scrollEventListener);
    }

    this.searchField.focus();
  }

  onSelectionChange(event: MatSelectChange) {
    if (!this.multiple) {
      this.writeValue(event.value);
    } else if (this.singleCheckboxSelect) {
      let newValue = event.value;
      if (this.value) {
        newValue = event.value.filter(val => !this.value.includes(val));
      }
      this.writeValue(newValue);
    } else {
      // add values
      for (const value of event.value) {
        if (value && !(this.value as string[])?.includes(value)) {
          this.writeValue(this.value ? [...this.value, value] : [value]);
        }
      }

      // remove values
      const currentItems = this.lazyLoadFn ? this.lazyLoadedItems$.getValue() : this.filteredAndSortedItems;
      const deselected = currentItems.filter(item => !(event.value as string[]).includes(item));
      if (deselected.length) {
        this.writeValue((this.value as string[]).filter(value => !deselected.includes(value)));
      }
    }
    this.selectionChange.emit(event);
  }

  onPressApply() {
    let { value } = this;
    if (this.showSortIndicator) {
      value = {
        key: value[0],
        value: this.isAscSort ? SortingType.ASC : SortingType.DESC
      };
    }
    this.pressApplyChanges.emit(value);
    this.select.close();
  }

  onPressRemove() {
    this.value = null;
    this.pressApplyChanges.emit(null);
  }

  writeValue(value: unknown): void {
    this.value = value;
  }
  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
}
