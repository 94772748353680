import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { AnimIconComponent } from './components/anim-icon/anim-icon.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommentComponent } from './components/comment/comment.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EmployeePreviewComponent } from './components/employee-preview/employee-preview.component';
import { InformationTagComponent } from './components/information-tag/information-tag.component';
import { InputComponent } from './components/input/input.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { TurbineComponent } from './components/loading-screen/turbine/turbine.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { DynamicDomAccessDirective } from './directives/dynamic-dom-access.directive';
import { IntersectionObserverElementDirective } from './directives/intersection-observer-element.directive';
import { IntersectionObserverListDirective } from './directives/intersection-observer-list.directive';
import { TwoDecimalPlacesDirective } from './directives/two-decimal-places.directive';
import { ByteToSizePipe, ContractStatusTranslation, DateTimezoneFixPipe, MgmtGroupTranslationPipe, ThousandsSeparatorPipe, TwoDecimalPlacesPipe } from './pipes';
import { EmployeeNamePipe } from './pipes/employee-name.pipe';
import { EnumToTextPipe } from './pipes/enum-to-text.pipe';
import { IsoToCountryPipe } from './pipes/iso-to-country.pipe';
import { TogglePillComponent } from './components/toggle-pill/toggle-pill.component';
import { ContractStatusColorPipe } from './pipes/contract-status-color.pipe';
import { MultiplicationPipe } from './pipes/multiplication.pipe';
import { PureFunctionPipe } from './pipes/pure-function.pipe';
import { ChipInputComponent } from './components/chip-input/chip-input.component';

@NgModule({
  declarations: [
    EmployeePreviewComponent,
    TurbineComponent,
    LoadingScreenComponent,
    CommentComponent,
    ConfirmationDialogComponent,
    ActionButtonComponent,
    InputComponent,
    DropdownComponent,
    ByteToSizePipe,
    DynamicDomAccessDirective,
    InformationTagComponent,
    TwoDecimalPlacesDirective,
    CheckboxComponent,
    EnumToTextPipe,
    EmployeeNamePipe,
    ContractStatusColorPipe,
    MultiplicationPipe,
    IntersectionObserverListDirective,
    IntersectionObserverElementDirective,
    TwoDecimalPlacesPipe,
    DateTimezoneFixPipe,
    UnsavedChangesDialogComponent,
    AnimIconComponent,
    MgmtGroupTranslationPipe,
    TogglePillComponent,
    IsoToCountryPipe,
    ThousandsSeparatorPipe,
    ContractStatusTranslation,
    ChipInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatChipsModule
  ],
  exports: [
    EmployeePreviewComponent,
    TurbineComponent,
    LoadingScreenComponent,
    CommentComponent,
    ConfirmationDialogComponent,
    InputComponent,
    DropdownComponent,
    ByteToSizePipe,
    DynamicDomAccessDirective,
    IntersectionObserverListDirective,
    IntersectionObserverElementDirective,
    InformationTagComponent,
    TwoDecimalPlacesDirective,
    ActionButtonComponent,
    CheckboxComponent,
    EnumToTextPipe,
    ContractStatusColorPipe,
    MultiplicationPipe,
    EmployeeNamePipe,
    TwoDecimalPlacesPipe,
    DateTimezoneFixPipe,
    UnsavedChangesDialogComponent,
    AnimIconComponent,
    MgmtGroupTranslationPipe,
    TogglePillComponent,
    ThousandsSeparatorPipe,
    ContractStatusTranslation,
    ChipInputComponent
  ],
  providers: [ContractStatusTranslation, MgmtGroupTranslationPipe]
})
export class UiModule {}
