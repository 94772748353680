<ci-sub-header title="Details of RepublishLog"></ci-sub-header>
<div class="container" *ngIf="data">
  <div class="side-bar">
    <div
      (click)="goBack()"
    >
      <span class="material-icons" style="color: white">fast_rewind</span>
    </div>
    <div style="display: flex; flex-direction: column">
      <div class="single-menu menu-rotate-small">
        <h4 class="headline-texts" style="writing-mode: vertical-rl">
          {{ data.id }}
        </h4>
      </div>
    </div>
  </div>

  <div class="records-details-container">
    <div *ngIf="data.state == 'Failed'">
      <ng-template #buttonsCenterTemplate>
        <h3 class="error">{{ formatErrorMessage(data.message) }}</h3>
      </ng-template>
      <ci-button-footer [buttonsCenterTemplate]="buttonsCenterTemplate">
      </ci-button-footer>
    </div>

    <div>
      <div class="inner one">
        <div class="box mat-elevation-z4">
          <p class="bold head">Coin Transaction State</p>
          <div class="content">
            <ci-transaction-state-progress-bar [data]="data"></ci-transaction-state-progress-bar>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="inner one">
        <div class="box mat-elevation-z4">
          <p class="bold head">Details</p>
          <div class="content">
            <div class="group">
              <div>
                <p>File Type:</p>
                <input matInput readonly [value]="data?.fileType?.displayedName || 'n/a'" />
              </div>
              <div>
                <p>Republished Elements :</p>
                <input matInput readonly [value]="data?.selectedElement || 'all objects'" />
              </div>
              <div>
                <p>Republished at:</p>
                <input
                  matInput
                  readonly
                  [value]="
                    data?.republishedAt
                      ? (data.republishedAt | date : 'MMM d, y (HH:mm z)' : 'UTC')
                      : 'n/a'
                  "
                />
              </div>
              <div>
                <p>Republished by:</p>
                <input
                  matInput
                  readonly
                  [value]="
                    data?.republishedBy
                      ? data.republishedBy.lastname + ' (GID: ' + data.republishedBy.gid + ')'
                      : 'n/a'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
