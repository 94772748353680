<ci-sub-header title="Settings"> </ci-sub-header>

<div class="container">
  <div class="side-bar">
    <div
      style="z-index: 1; cursor: pointer; padding-top: 15px"
      (click)="openDashboardPage()"
    >
      <span class="material-icons" style="color: white">fast_rewind</span>
    </div>
    <div style="display: flex; flex-direction: column"></div>
  </div>
  <div>
    <div>
      <ng-template #buttonsRightTemplate>
        <coin-action-button
          mat-raised-button
          svgIcon="save"
          [ngClass]="'primary'"
          (click)="openCreateFileTypePopup()"
        >
          Add New File Type
        </coin-action-button>
      </ng-template>
      <ci-button-footer [buttonsRightTemplate]="buttonsRightTemplate">
      </ci-button-footer>
    </div>
    <div class="records-details-container smaller">
      <div>
        <h4>Currently used File Types</h4>
        <div class="target-view">
          <div class="inner two">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <ci-charts level="fileTypes"></ci-charts>
            </div>
            <div class="box mat-elevation-z4">
              <p class="bold head">Summary</p>
              <div class="content">
                <ci-filetype-overview
                  [loading]="loading"
                ></ci-filetype-overview>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
