<div
  class="lib-input admin-form-field-outline admin-form-field-outline--shadow admin-form-field-outline--dynamic-height">
  <span class="material-icons pre-icon" *ngIf="preIcon">{{ preIcon }}</span>
  <span class="pre-symbol" *ngIf="preSymbol">{{ preSymbol }}</span>
  <mat-form-field
    class="lib-input coin-form-field-outline {{
      addClass ? 'coin-form-field-outline--' + addClass : ''
    }}"
    [ngClass]="inputIconClasses"
    appearance="outline"
    floatLabel="always"
  >
    <mat-label *ngIf="label"
      >{{ label }}
      <span>{{ errorMsg && '(' + errorMsg + ')' }}</span></mat-label
    >

    <input
      *ngIf="!isNumber && !isInteger"
      (keyup.enter)="onEnter()"
      (focus)="focusEnterLeave('enter')"
      [ngStyle]="{ 'text-align': textCenter ? 'center' : 'left' }"
      (focusout)="focusEnterLeave('leave')"
      [placeholder]="placeholder"
      matInput
      [(ngModel)]="value"
      [disabled]="disabled"
      autocomplete="off"
      [type]="type"
      #input
    />
    <input
      *ngIf="isNumber || isInteger"
      (keyup.enter)="onEnter()"
      (focus)="focusEnterLeave('enter')"
      [ngStyle]="{ 'text-align': textCenter ? 'center' : 'left' }"
      (focusout)="focusEnterLeave('leave')"
      [placeholder]="placeholder"
      matInput
      [(ngModel)]="value"
      [disabled]="disabled"
      [type]="type"
      [step]="isInteger ? 1 : 0.01"
      #input
    />
    <div #svgicon class="svg-icon"></div>
    <div #svgicon class="svg-icon"></div>
  </mat-form-field>
  <span
    class="material-icons after-icon"
    [ngClass]="{ presym: preSymbol }"
    *ngIf="afterIcon"
    >{{ afterIcon }}</span
  >
  <span class="after-symbol" *ngIf="afterSymbol">{{ afterSymbol }}</span>
</div>
