<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>Republish Entity from Current Base</h3>
    </div>

    <div class="box">
      <div class="loading-container" *ngIf="loading">
        <app-loading-screen
          [local]="true"
          [contrast]="true"
        ></app-loading-screen>
      </div>
      <div class="group" *ngIf="!loading">
        <div class="flex-container">
          <coin-dropdown
            *ngIf="entities.length"
            [items]="entities"
            [shadow]="true"
            style="margin-left: 20px"
            style="margin-right: 20px"
            label="File Type"
            [displayFn]="dropdownDisplayFn"
            (selectionChange)="applySelectionChange($event)"
            [disabled]="publishing"
            [disableOptionFn]="disableOption"
            [disableCommentFn]="disableComment"
          >
          </coin-dropdown>
        </div>
        <div
          class="flex-container"
          *ngIf="selectedFileTypePrefix"
          style="justify-content: flex-start; flex: 0 1 35%"
        >
          <coin-checkbox
            (onToggle)="onToggle($event)"
            [checked]="fullBaseActive"
          ></coin-checkbox>
          <p><strong>Full Base</strong></p>
        </div>
        <div class="flex-container" *ngIf="selectedFileTypePrefix">
          <ng-template ngFor let-key [ngForOf]="selectedFileTypeKeys">
            <coin-input
              type="text"
              [(ngModel)]="objectIdentifiers[key]"
              [label]="key"
              [disabled]="fullBaseActive"
            ></coin-input>
          </ng-template>
        </div>
      </div>
      <div class="buttons">
        <coin-action-button
          class="secondary"
          svgIcon="cancel"
          (click)="close()"
        >
          {{ publishing ? 'Cancel' : 'Close' }}
        </coin-action-button>
        <coin-action-button
          class="primary"
          svgIcon="upload"
          [disabled]="!selectedFileTypeKeys || !isValid() || publishing"
          (click)="save()"
          style="box-shadow: rgb(169, 169, 169) 0px 2px 5px 0px"
        >
          {{ publishing ? 'Publishing' : 'Publish' }}
        </coin-action-button>
      </div>
    </div>
  </div>
</div>
