import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ci-organisation_management',
  templateUrl: './organisation_management.component.html',
  styleUrls: ['./organisation_management.component.scss']
})
export class OrganisationManagementComponent implements OnInit {
  openList: 'transfer' | 'structure' | 'email';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.openList = 'transfer';
  }

  openTransferListPage() {
    this.openList = 'transfer';
    this.router.navigate([]);
  }
  openStructureListPage() {
    this.openList = 'structure';
    this.router.navigate([]);
  }
  openEmailListPage() {
    this.openList = 'email';
    this.router.navigate([]);
  }
}
