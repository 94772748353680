<div class="upload-field">
  <div #buttonsRightTemplate>
    <coin-action-button
      mat-raised-button
      ngClass="primary"
      svgIcon="upload"
      (click)="openCreateEmailListEntryPopup()"
    >
      Add Email List Entry
    </coin-action-button>
  </div>
</div>

<ci-simple-table
  [header]="selectedTableHeaders"
  [service]="emailListService"
  [serverPaginated]="true"
  [serverside]="true"
  [presetQueryParams]="queryParameters$ | async"
  [updaterIntervalInMs]="updaterIntervalInMs"
>
  <ng-template #columns let-item>
    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <i
            class="edit-button material-icons"
            (click)="openUpdateEmailListEntryPopup(item)"
            >edit</i
          >
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <i
            class="edit-button material-icons"
            (click)="openDeleteEmailListEntryPopup(item)"
            >delete</i
          >
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.Gid }}
          </p>
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.EmailAddress }}
          </p>
        </div>
      </div>

      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.CreatedAt | date: 'MMM d, y (HH:mm)' : 'UTC' }}
          </p>
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.CreatedByGid }}
          </p>
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.LastModifiedAt | date: 'MMM d, y (HH:mm)' : 'UTC' }}
          </p>
        </div>
      </div>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p>
            {{ item.LastModifiedByGid }}
          </p>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ci-simple-table>
