import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Fn } from '@coin/shared/util-models';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let lottie: any;
@Component({
  selector: 'coin-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() preselect;
  @Input() isNumber: boolean;
  @Input() isInteger: boolean;
  @Input() addClass;
  @Input() preIcon;
  @Input() preSymbol;
  @Input() afterIcon;
  @Input() afterSymbol;
  @Input() disabled: boolean;
  @Input() placeholder = '';
  @Input() type: string;
  @Input() label: string;
  @Input() svgIcon;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() textCenter = false;
  @Input() errorMsg: string;

  @Output() enterClicked: EventEmitter<number> = new EventEmitter();

  @ViewChild('svgicon') svgicon: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  rndNum = uuid();

  public inputIconClasses: Record<string, unknown>;

  private _value: string = null;
  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }
  get value() {
    return this._value;
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  constructor() {}

  ngOnInit() {
    this.setIconClasses();
  }

  ngAfterViewInit(): void {
    if (!this.svgIcon) {
      return;
    }

    lottie.loadAnimation({
      container: this.svgicon.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: this.rndNum,
      path: `assets/svgs/icon-data/${this.svgIcon}.json` // the path to the animation json
    });
    if (this.svgIcon === 'search') {
      lottie.goToAndStop(27, true, this.rndNum);
    }
  }

  private setIconClasses() {
    this.inputIconClasses = {
      'admin-form-field-outline--preIcon': this.preIcon || this.preSymbol,
      'admin-form-field-outline--afterIcon': this.afterIcon,
      'admin-form-field-outline--svgIcon': this.svgIcon
    };
  }

  focusEnterLeave(type) {
    if (!this.svgIcon) {
      return;
    }

    if (type === 'enter') {
      lottie.play(this.rndNum);
    } else if (this.svgIcon === 'search') {
      lottie.goToAndStop(27, true, this.rndNum);
    } else {
      lottie.stop(this.rndNum);
    }
  }

  public focus() {
    this.input.nativeElement.focus();
  }

  public clear() {
    this.writeValue('');
  }

  onEnter() {
    this.enterClicked.emit();
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
}
