import { UntypedFormControl } from '@angular/forms';

export class TinyHelpers {
  static noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static deepCopy(object: unknown) {
    return object ? JSON.parse(JSON.stringify(object)) : undefined;
  }

  static deepCompare(object1: unknown, object2: unknown) {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  static escapeHtml(html: string) {
    return html ? html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;') : '';
  }

  static unescapeHtml(html: string) {
    return html
      ? html
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, `'`)
      : '';
  }

  static convertDotToComma(text: string): string {
    return text?.replace(/[.]/g, ',');
  }

  static pascalcaseToText(text: string): string {
    return text?.replace(/([A-Z])/g, ' $1')?.trim();
  }

  static camelCaseToText(text: string): string {
    const result = text.replace(/([A-Z])/g, ' $1');

    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static camelCaseToKebabCase(text: string): string {
    return text.replace(/([A-Z])/g, x => `-${x.toLowerCase()}`).trim();
  }

  static stringToCamelCase(string: string): string {
    return string
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  }

  static hasDuplicates(array: string[][], index: number): boolean {
    const values: string[] = array.map(item => item[index]);
    return values.some((value, index) => value && values.indexOf(value) !== index);
  }

  static scrollOverAmount(e, lastScrollTop: number, amount = 0.9) {
    const progressAmount =
      (e.target.scrollTop + e.target.offsetHeight - (lastScrollTop + e.target.offsetHeight)) / (e.target.scrollHeight - (lastScrollTop + e.target.offsetHeight));
    return progressAmount > amount;
  }

  static isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  public static isBetween(value: number, a: number, b: number): boolean {
    const min = Math.min(a, b);
    const max = Math.max(a, b);
    return value < max && value > min;
  }
}
