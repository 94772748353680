<div class="container">
  <div class="content">
    <ng-container *ngIf="action === Action.Edit || action === Action.New">
      <div class="dialog-headline">
        <h3 *ngIf="action === Action.Edit">Edit file type</h3>
        <h3 *ngIf="action === Action.New">Create new file type</h3>
      </div>

      <div class="box">
        <div class="row">
          <coin-input
            type="text"
            [(ngModel)]="item.displayedName"
            placeholder="Name"
            label="Name *"
            matTooltip="Name of the file type"
            matTooltipPosition="below"
            [errorMsg]="validateName()"
          ></coin-input>
          <coin-input
            type="text"
            [(ngModel)]="item.prefix"
            placeholder="Prefix"
            label="Prefix *"
            [disabled]="action === Action.Edit"
            matTooltip="Unique identifier for each file type (should be a short 2-4 digit sequence of letters - for internal use only)"
            matTooltipPosition="below"
            [errorMsg]="validatePrefix()"
          ></coin-input>
        </div>
        <div class="row">
          <coin-input
            type="text"
            [(ngModel)]="item.identifier"
            placeholder="Identifier"
            label="Identifier *"
            [disabled]="action === Action.Edit"
            matTooltip="Attribute (or coma-separated list of attributes) which uniquely identify each object"
            matTooltipPosition="below"
            [errorMsg]="validateIdentifier()"
          ></coin-input>
          <div class="row">
            <coin-checkbox
              (onToggle)="checkThresholdBox($event)"
              matTooltip="Select Threshold"
              [checked]="thresholdCheckBoxState"
            ></coin-checkbox>

            <coin-input
              type="text"
              [(ngModel)]="temporaryThreshold"
              placeholder="Threshold"
              label="Threshold"
              [disabled]="disableThreshold"
              matTooltip="Percentage of valid datasets which triggers an automatic import/reject (0 will automatically import any upload, disable for manual decision)"
              matTooltipPosition="below"
            ></coin-input>
          </div>
        </div>

        <div class="row">
          <coin-dropdown
            label="File Format *"
            placeholder="Format"
            [(ngModel)]="item.fileFormat"
            (selectionChange)="onFileFormatSelect($event)"
            [items]="FORMAT"
            [disabled]="action === Action.Edit"
            matTooltip="File format of the uploaded file"
            matTooltipPosition="below"
          >
          </coin-dropdown>
          <coin-input
            type="text"
            [(ngModel)]="item.delimiter"
            placeholder="Delimiter"
            label="Delimiter"
            [disabled]="disableDelimiter || action === Action.Edit"
            matTooltip="Character which separates the columns of the csv file"
            matTooltipPosition="below"
            [errorMsg]="validateDelimiter()"
          ></coin-input>
        </div>

        <div class="row">
          <coin-input
            type="text"
            [(ngModel)]="item.entityWrapper"
            placeholder="Entity Wrapper"
            label="Entity Wrapper"
            [disabled]="disableEntityWrapper"
            matTooltip="Name of root level attribute which contains the list of objects"
            matTooltipPosition="below"
            [errorMsg]="validateEntityWrapper()"
          ></coin-input>

          <chip-input
            placeholder="new Address..."
            [regExp]="emailRegex"
            [chips]="item.emailAddress"
            label="Email Addresses"
            matTooltip="multiple addresses possible - can be separated with Comma or Enter. Navigation with Arrows is possible"
            matTooltipPosition="below"
          ></chip-input>
        </div>

        <div class="row">
          <coin-input
            type="text"
            [(ngModel)]="temporaryRetention"
            placeholder="Retention Time"
            label="Retention Time"
            matTooltip="Amount of days which job data will be hold in S3 bucket. Does not affect data in the database (0 equals eternal retention)"
            matTooltipPosition="below"
            [errorMsg]="validateRetention()"
          ></coin-input>

          <p style="margin-right: 100px">
            <coin-checkbox
              (onToggle)="checkSlackNotificationBox($event)"
              matTooltip="Send Slack Notification"
              [checked]="slackNotificationCheckBoxState"
            ></coin-checkbox>
            Send Slack Notifications
          </p>
        </div>
        <div *ngIf="action === Action.New; then eaform"></div>
        <ng-template #eaform>
          <ci-export-attributes-form
            (allValid)="validExportAttributes = $event"
            (allRequired)="requiredExportAttributes = $event"
            [entity]="item"
            [action]="action"
          ></ci-export-attributes-form
        ></ng-template>

        <div *ngIf="action === Action.New">
          <div class="row">
            <coin-input
              *ngIf="schemaSourceFileName"
              label="Selected Schema Source File *"
              style="margin-left: 20px"
              matInput
              [disabled]="true"
              [type]="'text'"
              [(ngModel)]="schemaSourceFileName"
            ></coin-input>
            <ng-template #buttonsRightTemplate>
              <coin-action-button
                mat-raised-button
                ngClass="primary"
                icon="select File"
                (click)="fileInput.click()"
                [disabled]="uploading"
              >
                {{
                  schemaSourceFileName
                    ? 'Change Schema Source File'
                    : 'Select Schema Source File'
                }}
                <input
                  #fileInput
                  type="file"
                  (change)="onFileSelected($event)"
                  style="display: none"
                />
              </coin-action-button>
            </ng-template>
            <ci-button-footer
              [buttonsRightTemplate]="buttonsRightTemplate"
              style="margin: 0"
            >
            </ci-button-footer>
          </div>
        </div>

        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            Cancel
          </coin-action-button>

          <div *ngIf="action === Action.Edit; then edit; else new"></div>
          <ng-template #edit>
            <coin-action-button
              class="primary"
              svgIcon="upload"
              (click)="save()"
              [disabled]="uploading || !checkIfValid()"
            >
              Save
            </coin-action-button>
          </ng-template>

          <ng-template #new>
            <coin-action-button
              class="primary"
              svgIcon="upload"
              (click)="create()"
              [disabled]="uploading || !checkIfValid()"
            >
              Create
            </coin-action-button></ng-template
          >
        </div>
        <mat-progress-bar
          [color]="progressBarColor"
          [mode]="progressMode"
          [value]="progressValue"
        >
        </mat-progress-bar>
      </div>
      <div class="info-footer">
        <p [class.error]="!validateRequired()">
          Fields marked with * are mandatory.
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="action === Action.Delete">
      <div class="dialog-headline">
        <h3>Delete file type</h3>
      </div>
      <div class="box">
        <div class="dialog-body">
          <p>Are you sure you want to delete this file type?</p>
        </div>
        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            Cancel
          </coin-action-button>
          <coin-action-button
            class="primary"
            svgIcon="delete"
            (click)="delete()"
            [disabled]="uploading"
          >
            Delete
          </coin-action-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
