<ci-sub-header title="Republish Logs"></ci-sub-header>

<div class="upload-field">
  <coin-action-button
    mat-raised-button
    ngClass="primary"
    svgIcon="upload"
    (click)="openObjectRefresherPopup()"
  >
    Republish Base Elements
  </coin-action-button>
</div>

<ci-simple-table
  [header]="selectedTableHeaders"
  [service]="republishService"
  [serverPaginated]="true"
  [serverside]="true"
  [presetQueryParams]="queryParameters$ | async"
  [isExpectedToChange]="isExpectedToChange"
  [updaterIntervalInMs]="updaterIntervalInMs"
>
  <ng-template #columns let-item>
    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <i class="edit-button material-icons" (click)="loadSummary(item)">{{
            item.state == 'Staged' ? 'edit' : 'visibility'
          }}</i>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p [class.error]="item.state == 'Failed'">
            {{ item.fileType.displayedName }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p class="ps ps--finalized">
            {{ item.state }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <div
            [class.error]="item.state == 'Failed'"
            #singleObject
            *ngIf="item.selectedElement; then selectedElement; else allElements"
          ></div>
          <ng-template #selectedElement
            ><p>{{ item.selectedElement }}</p></ng-template
          >
          <ng-template #allElements><p>all objects</p></ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p [class.error]="item.state == 'Failed'">
            {{ item.republishedAt | date : 'MMM d, y' }}
            <br />
            {{ item.republishedAt | date : '(HH:mm, z)' : 'UTC' }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="content-column">
        <div class="row" *ngIf="item">
          <p [class.error]="item.state == 'Failed'">
            {{ item.republishedBy.firstname }} {{ item.republishedBy.lastname
            }}<br />
            (GID: {{ item.republishedBy.gid }})
          </p>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ci-simple-table>
