import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FileTypeDto } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { APIGatewayResponse } from '@coin/importer/common/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileTypePopupComponent } from '../../components/file-type-popup/file-type-popup.component';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'ci-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  loading = true;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private fileTypeService: FileTypeService,
    private toast: ToastService,
    private _matDialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.fileTypeService.fileTypeSubject$.getValue()?.length) {
      await this.fileTypeService.loadFileTypesToSubject();
    }
    this.loading = false;
  }

  openDashboardPage(): void {
    this.router.navigate(['']);
  }

  // for testing
  deleteObjects(item: FileTypeDto): void {
    this.fileTypeService
      .deleteObjects(item)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIGatewayResponse) => {
        if (res.statusCode) {
          const { message } = JSON.parse(res.body);
          if (res.statusCode === 409) {
            this.toast.info(message);
          } else {
            this.toast.error(message);
          }
        }
      });
  }

  openCreateFileTypePopup(): void {
    const item = { exportAttributes: [], emailAddress: [] } as FileTypeDto;
    const ret = this._matDialog.open(FileTypePopupComponent, {
      data: { item, action: PopupAction.New }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
