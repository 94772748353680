import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthGmsClientConfig } from '../models/auth-gms-client.config';
import { AuthUser } from '../models/user-auth.model';
import { LoadLoggedInUser } from '../store';

export const AuthGmsClientConfigService = new InjectionToken<AuthGmsClientConfig>('AuthGmsClientConfig');

@Injectable()
export class AuthService {
  private REDIRECT_URL_KEY = 'redirectUrl';
  private config: AuthGmsClientConfig;
  private authConfig: AuthConfig;
  private get router(): Router {
    return this.injector.get(Router);
  }
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(AuthGmsClientConfigService) config,
    private injector: Injector,
    private store: Store,
    private oauthService: OAuthService
  ) {
    this.config = config;
    this.authConfig = {
      clientId: this.config.clientId,
      issuer: this.config.issuer,
      redirectUri: this.config.callbackUrl,
      responseType: 'code',
      scope: this.config.scopes,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true
    };
    this.listenOnOAuthEvents();
  }

  public async getUser(): Promise<AuthUser> {
    const user: string = await this.getIdToken();
    if (user) {
      return this.decodeToken(user);
    }
  }

  public getIdToken(): Promise<string> {
    return Promise.resolve(this.oauthService.getIdToken() || '');
  }

  public getAccessToken(): Promise<string> {
    return Promise.resolve(this.oauthService.getIdToken() || '');
  }

  /**
   * @param redirectPath
   */
  public async login(redirectPath?: string): Promise<void> {
    try {
      await this.initOAuthService();
      await this.oauthService.initLoginFlow();
      await localStorage.setItem(this.REDIRECT_URL_KEY, redirectPath.toString());
    } catch (e) {
      console.error(e);
    }
  }

  public async refreshToken(): Promise<void> {
    try {
      await this.initOAuthService();
      await this.oauthService.refreshToken();
    } catch (error) {
      console.error(error);
    }
  }

  public logOff(): void {
    this.oauthService.logOut();
    this.router.navigate(['/logout']);
  }

  public getRemainingTime(): number {
    const expirationInMilliseconds = this.oauthService.getIdTokenExpiration() - Date.now();
    return expirationInMilliseconds / 1000;
  }

  private listenOnOAuthEvents(): void {
    this.isAuthenticated$.next(this.oauthService.hasValidIdToken());
    this.oauthService.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.isAuthenticated$.next(this.oauthService.hasValidIdToken()));
  }

  public async handleLoginCallback(): Promise<void> {
    try {
      await this.initOAuthService();
      await this.oauthService.tryLoginCodeFlow();

      const stateUrl = (await localStorage.getItem(this.REDIRECT_URL_KEY)) || '/';
      await localStorage.removeItem(this.REDIRECT_URL_KEY);

      await this.store.dispatch(new LoadLoggedInUser()).pipe(take(1)).toPromise();
      await this.router.navigateByUrl(stateUrl);
    } catch (error) {
      console.log(error);
      this.router.navigate(['/unauthorized']);
    }
  }

  private async initOAuthService(): Promise<void> {
    this.oauthService.configure(this.authConfig);
    await this.oauthService.loadDiscoveryDocument();
  }

  public decodeToken(tokenValue: string): AuthUser {
    let decoded = '{}';

    if (tokenValue) {
      decoded = this.b64DecodeUnicode(tokenValue.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'));
    }

    return JSON.parse(decoded);
  }

  private b64DecodeUnicode(str: string): string {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(char => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
  }
}
