import { StatsDto } from '@coin/importer/dto/util';
import { Doughnut, InnerPercentage } from './doughnut';

export class DatasetDoughnut extends Doughnut {
  constructor(data: StatsDto) {
    super(data);
  }

  protected fillData(input: StatsDto): void {
    const changed: InnerPercentage = [input.changedDatasets, input.totalDatasets, 'changed datasets'];

    const invalid: InnerPercentage = [input.invalidDatasets, input.totalDatasets, 'invalid datasets'];

    this.addEntry(input.invalidDatasets, 'Invalid Datasets', this.colorPresets.error[0]);
    this.addEntry(input.changedDatasets - input.invalidDatasets, 'Valid Datasets', this.colorPresets.pass);
    this.addEntry(input.totalDatasets - input.changedDatasets, 'Unchanged Datasets', this.colorPresets.unchanged);

    this.addInnerPercentageOfTotal(changed, invalid);
  }

  protected hasNoData(data: StatsDto): boolean {
    return !data?.totalDatasets;
  }
}
