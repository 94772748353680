<div class="group">
  <div>
    <p>File Name:</p>
    <input matInput readonly [value]="(data?.fileName | fileName) || 'n/a'" />
  </div>
  <div>
    <p>Load Type:</p>
    <input matInput readonly [value]="data?.loadLevel || 'n/a'" />
  </div>

  <div>
    <p>File Type:</p>
    <input matInput readonly [value]="data?.fileType?.displayedName || 'n/a'" />
  </div>
  <div>
    <p>Duration of Import:</p>
    <input matInput readonly [value]="data?.stats?.glueDurationInMs ? (data.stats.glueDurationInMs | duration) : 'n/a'" />
  </div>
  <div>
    <p>File Size:</p>
    <input matInput readonly [value]="data?.uploadSizeInBytes | fileSize" />
  </div>
  <div>
    <p>Uploaded at:</p>
    <input matInput readonly [value]="data?.uploadedAt ? (data.uploadedAt | date: 'MMM d, y (HH:mm z)' : 'UTC') : 'n/a'" />
  </div>
  <div></div>
  <div>
    <p>Size of Delta:</p>
    <input matInput readonly [value]="data?.stats?.deltaSizeInBytes | fileSize" />
  </div>
</div>
<mat-divider></mat-divider>
<div class="group">
  <div>
    <p>Total Datasets:</p>
    <input matInput readonly [value]="data?.stats?.totalDatasets ? (data.stats.totalDatasets | number: '1.0') : '0'" />
  </div>
  <div>
    <p>Changed Datasets:</p>
    <input matInput readonly [value]="data?.stats?.changedDatasets ? (data.stats.changedDatasets | number: '1.0') : '0'" />
  </div>
  <div>
    <p>Removed Duplicates:</p>
    <input matInput readonly [value]="data?.stats?.removedDuplicatesCount ? (data.stats.removedDuplicatesCount | number: '1.0') : '0'" />
  </div>
  <div>
    <p class="indented">Valid Datasets:</p>
    <input
      matInput
      readonly
      [value]="data?.stats?.changedDatasets ? (data.stats.changedDatasets - (data?.stats?.invalidDatasets ? data.stats.invalidDatasets : 0) | number: '1.0') : '0'" />
  </div>
  <div>
    <p>Datasets marked for deletion:</p>
    <input matInput readonly [value]="data?.stats?.objectsMarkedForDeletionCount ? data?.stats?.objectsMarkedForDeletionCount : 'n.a.'" />
  </div>
  <div>
    <p class="indented">Invalid Datasets:</p>
    <input matInput readonly [value]="data?.stats?.invalidDatasets ? (data.stats.invalidDatasets | number: '1.0') : '0'" />
  </div>
  <div>
    <coin-action-button
      mat-raised-button
      [disabled]="!data?.stats?.invalidDatasetsUrl || isLoading || !data?.filesInS3"
      ngClass="secondary"
      svgIcon="download"
      (click)="downloadFile('invalidDatasetsUrl')">
      Invalid Dataset List
    </coin-action-button>
  </div>
  <div>
    <coin-action-button
      mat-raised-button
      [disabled]="!data?.stats?.deletedEtlIdsUrl || isLoading || !data?.filesInS3"
      ngClass="secondary"
      svgIcon="download"
      (click)="downloadFile('deletedEtlIdsUrl')">
      Object Deletion List
    </coin-action-button>
  </div>
  <div>
    <coin-action-button
      mat-raised-button
      [disabled]="!data?.stats?.duplicateLogUrl || isLoading || !data?.filesInS3"
      ngClass="secondary"
      svgIcon="download"
      (click)="downloadFile('duplicateLogUrl')">
      Duplicate Removal Log
    </coin-action-button>
  </div>
</div>
