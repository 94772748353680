<ci-sub-header title="Details of Log"></ci-sub-header>
<div class="container" *ngIf="data">
  <div class="side-bar">
    <div (click)="goBack()">
      <span class="material-icons" style="color: white">fast_rewind</span>
    </div>
    <div style="display: flex; flex-direction: column">
      <div class="single-menu menu-rotate-small">
        <h4 class="headline-texts" style="writing-mode: vertical-rl">
          {{ (data.fileName | fileName) || 'n/a' }}
        </h4>
      </div>
    </div>
  </div>
  <div>
    <div
      *ngIf="
        data.state === 'Staged' &&
        !data.fileType.threshold &&
        data.fileType.threshold !== 0
      "
    >
      <ng-template #buttonsRightTemplate>
        <coin-action-button
          mat-raised-button
          *ngFor="let enum of actionEnum | keyvalue"
          svgIcon="save"
          [ngClass]="enum.value === 'Reject' ? 'secondary' : 'primary'"
          (click)="openPopup(enum.value)"
        >
          {{ enum.value }}
        </coin-action-button>
      </ng-template>
      <ci-button-footer [buttonsRightTemplate]="buttonsRightTemplate">
      </ci-button-footer>
    </div>
    <div *ngIf="data.state === 'Failed'">
      <ng-template #buttonsCenterTemplate>
        <h3 class="error">{{ formatErrorMessage(data.message) }}</h3>
      </ng-template>
      <ci-button-footer [buttonsCenterTemplate]="buttonsCenterTemplate">
      </ci-button-footer>
    </div>

    <div
      class="records-details-container"
      [class.smaller]="data.state === 'Staged' || data.state === 'Failed'"
    >
      <div *ngIf="data.coinProcessingState" class="target-view">
        <div class="inner one">
          <div class="box mat-elevation-z4">
            <p class="bold head">Coin Transaction State</p>
            <div class="content">
              <ci-transaction-state-progress-bar
                [data]="data"
              ></ci-transaction-state-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="data.state !== 'Staged'" class="target-view">
        <div class="inner one">
          <div class="box mat-elevation-z4">
            <p class="bold head">Decision on Import</p>
            <div class="content">
              <ci-import-decision [data]="data"></ci-import-decision>
            </div>
          </div>
        </div>
      </div>
      <h4>Analysis of Datasets</h4>
      <div class="target-view">
        <div class="inner two">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <ci-charts [importStats]="data.stats" level="datasets"></ci-charts>
          </div>
          <div class="box mat-elevation-z4">
            <p class="bold head">Summary</p>
            <div class="content">
              <ci-import-details [data]="data"></ci-import-details>
            </div>
          </div>
        </div>
      </div>
      <h4>Analysis of Changes with Validation Rules</h4>
      <div class="target-view">
        <div class="inner two">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <ci-charts [importStats]="data.stats" level="changes"></ci-charts>
          </div>
          <div class="box mat-elevation-z4">
            <p class="bold head">Summary</p>
            <div class="content">
              <ci-validation-list [importStats]="data.stats">
              </ci-validation-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
