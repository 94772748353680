<div class="unsaved-changes-dialog">
  <div class="dialog-headline">
    <h3 style="color: white">{{ data.title }}</h3>
  </div>
  <div class="uc-dialog content">
    <p>{{ data.msg }}</p>

    <div class="buttons-container">
      <div class="spacer"></div>
      <coin-action-button (click)="goBack()" svgIcon="cancel">{{
        'dialog.unsaved-changes.cancel'
      }}</coin-action-button>
      <coin-action-button
        class="secondary"
        (click)="proceed('noSave')"
        svgIcon="forward"
        >{{ 'dialog.unsaved-changes.no-save' }}</coin-action-button
      >
      <coin-action-button
        class="primary"
        (click)="proceed('save')"
        svgIcon="save"
        >{{ 'dialog.unsaved-changes.save' }}</coin-action-button
      >
    </div>
  </div>
</div>
