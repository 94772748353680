import { environment } from '@coin/shared/util-environments';
import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeSwitchService {
  status = false;

  @Output() switch: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  getCurrent() {
    return this.status;
  }

  toggle(change: boolean) {
    environment.baseButtonColorLight = !change;
    this.status = change;
    this.switch.emit(change);
  }
}
