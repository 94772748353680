import { AccessRole } from '@coin/importer/common/util';

export interface Role {
  id?: string;
  name: string;
  permissions: Permission[];
  isInternal?: boolean;
  hasClusterGroup?: boolean;
  roleParameters?: Record<string, string | number>[];
  hasParams?: boolean;
  isPulseRelevant?: boolean;
}

export interface Permission {
  id?: string;
  resource: PermissionResource | string;
  action: PermissionAction | string;
}

export enum PermissionResource {
  All = 'All',
  OrganisationManagementAdmin = AccessRole.OrganisationManagementAdmin,
  OrganisationManagementUser = AccessRole.OrganisationManagementUser
}

export enum PermissionAction {
  All = 'All',
  Read = 'Read',
  Create = 'Create',
  Download = 'Download',
  Delete = 'Delete',
  Update = 'Update'
}
