import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { environment } from '@coin/shared/util-environments';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DarkModeSwitchService, LottieFilesService } from '../../services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let lottie: any;

@Component({
  selector: 'coin-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss', './button-icons.scss']
})
export class ActionButtonComponent implements AfterViewInit, OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<void> = new EventEmitter();
  @Input() disabled = false;
  @Input() loading = false;

  // STYLING
  @Input() addClass: string;
  @Input() circle = false;

  // ICONS
  @Input() svgIcon;
  @Input() showArrow = true;
  @Input() svgSpeed = 0.6;
  @Input() iconTextDistance = 20;
  @Input() iconTextMargin = 8;
  @Input() iconPos = 'right';

  @Input() noicon = false;
  @Input() arrowPos = 'right';
  @Input() icon = 'arrow';
  @Input() matIcon = '';
  @Input() height = 18;
  @Input() ellipsis = false;
  @Input() small = false;
  @Input() bgColor?: string;

  @ViewChild('svgicon') svgicon: ElementRef;
  @ViewChild('button') button: ElementRef;

  ripple: HTMLDivElement;
  lottieIconId: string;

  get iconClass() {
    return this.noicon || !!this.svgIcon ? '' : `${this.icon}--${this.arrowPos}`;
  }

  get whiteText() {
    return this.darkModeState.getCurrent() && !this.elementRef.nativeElement.classList.contains('primary');
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private lottieFilesService: LottieFilesService,
    private elementRef: ElementRef,
    private darkModeState: DarkModeSwitchService
  ) {}

  ngOnInit(): void {
    this.lottieIconId = uuid();
  }

  ngAfterViewInit() {
    this.addSvgIcon();
    this.darkModeState.switch.pipe(takeUntil(this.destroy$)).subscribe(() => this.addSvgIcon());
  }

  private addSvgIcon() {
    if (!this.svgIcon) {
      return;
    }

    const newFullIconTitle = this.svgIcon + (this.isWhiteIcon() ? '-white' : '');
    const existingLSvg = this.lottieFilesService.getLottieFile(newFullIconTitle);

    if (existingLSvg) {
      this.handleLottieContent(existingLSvg);
    } else {
      this.lottieFilesService
        .getLottieJson(newFullIconTitle)
        .pipe(takeUntil(this.destroy$))

        .subscribe(lottieSvg => {
          this.lottieFilesService.setLottieFile(newFullIconTitle, lottieSvg);
          this.handleLottieContent(lottieSvg);
        });
    }
  }

  private isWhiteIcon() {
    if (this.button.nativeElement.parentNode.classList.contains('primary')) {
      return true;
    }
    const whiteColorStyles = ['white', '#fff', '#ffffff', 'rgb(255, 255, 255)'];
    const buttonStyles = window.getComputedStyle(this.button.nativeElement);
    const buttonColorValue = buttonStyles.getPropertyValue('color');

    if (whiteColorStyles.includes(buttonColorValue)) {
      return true;
    }

    return environment.baseButtonColorLight && this.whiteText;
  }

  handleLottieContent(file) {
    lottie.destroy(this.lottieIconId);
    lottie.loadAnimation({
      container: this.svgicon.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: this.lottieIconId,
      animationData: file
      // path: `assets/svgs/icon-data/${this.svgIcon}.json` // the path to the animation json
    });

    lottie.setSpeed(this.svgSpeed);

    if (this.svgIcon === 'add') {
      lottie.goToAndStop(27, true, this.lottieIconId);
    }
    if (this.svgIcon === 'print') {
      lottie.goToAndStop(27, true, this.lottieIconId);
    }
    if (this.svgIcon === 'link' || this.svgIcon === 'link-white') {
      lottie.goToAndStop(40, true, this.lottieIconId);
    }
  }

  mouseEnterLeave(type: string) {
    if (!this.svgIcon) {
      return;
    }

    if (type === 'enter') {
      lottie.play(this.lottieIconId);
    } else {
      lottie.stop(this.lottieIconId);
      if (this.svgIcon === 'add') {
        lottie.goToAndStop(27, true, this.lottieIconId);
      }
      if (this.svgIcon === 'print') {
        lottie.goToAndStop(27, true, this.lottieIconId);
      }
      if (this.svgIcon === 'link' || this.svgIcon === 'link-white') {
        lottie.goToAndStop(40, true, this.lottieIconId);
      }
    }
  }

  onClick(e: MouseEvent) {
    e.stopPropagation();

    if (this.disabled || this.loading) {
      return;
    }
    this.click.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
