<div class="container">
  <div class="content">
    <ng-container>
      <p class="export-attributes">Export Attributes</p>

      <div class="ea-list">
        <div
          class="content-column"
          *ngFor="let item of entity.exportAttributes; let i = index"
        >
          <div class="row">
            <coin-dropdown
              label="Level *"
              placeholder="Level"
              [(ngModel)]="item.level"
              (selectionChange)="onSelect($event, i)"
              [items]="LEVEL"
            >
            </coin-dropdown>
            <coin-input
              type="text"
              [(ngModel)]="item.key"
              placeholder="Key"
              label="Key *"
              [errorMsg]="validateKey(item.key)"
            ></coin-input>
            <coin-input
              type="text"
              [(ngModel)]="item.value"
              placeholder="Value"
              label="Value *"
            ></coin-input>
            <ng-container>
              <i class="material-icons delete-button" (click)="remove(i)"
                >delete</i
              >
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container>
        <div style="width: 100%; display: flex; justify-content: center">
          <coin-action-button
            mat-raised-button
            ngClass="primary button"
            svgIcon="add"
            [circle]="true"
            (click)="add()"
            [small]="true"
            style="width: min-content"
          >
          </coin-action-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
