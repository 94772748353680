<div>
  <div class="group">
    <div>
      <p>Action:</p>
      <input matInput readonly [value]="decision?.action || 'n/a'" />
    </div>
    <div>
      <p>Published to Kafka:</p>
      <input matInput readonly [value]="decision?.publish || false" />
    </div>
    <div>
      <p>Comment:</p>
    </div>
    <div>
      <p>Timestamp:</p>
      <input
        matInput
        readonly
        [value]="
          decision?.decidedAt
            ? (decision.decidedAt | date : 'MMM d, y (HH:mm z)')
            : 'n/a'
        "
      />
    </div>
    <div>
      <textarea
        matInput
        readonly
        rows="3"
        [value]="decision?.comment || 'n/a'"
      ></textarea>
    </div>
    <div>
      <p>Person / Organization:</p>
      <input
        matInput
        readonly
        [value]="decision?.decidedBy?.lastname || 'n/a'"
      />
    </div>
  </div>
</div>
