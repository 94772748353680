import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ci-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent {
  @Input() title: string = '';

  constructor(private router: Router) {}

  openUploadPage() {
    this.router.navigate(['']);
  }

  openRepublishPage() {
    this.router.navigate(['republish']);
  }

  openSettingsPage() {
    this.router.navigate(['settings']);
  }

  openOrgManagementPage() {
    this.router.navigate(['organisation-management']);
  }
}
