<div class="wrapper {{ addClass ? 'wrapper--' + addClass : '' }}" (click)="clicked()" [class.contrast]="contrast" [class.dark]="dark">
  <div class="checkbox" [style.width]="size + 'px'" [style.height]="size + 'px'" [class.disabled]="disabled" [class.with-text]="hasText">
    <div class="outer-circle">
      <div class="checked-circle" [class.checked]="checked" [@anim]="checked ? 'checked' : 'unchecked'"></div>
    </div>
  </div>
  <p #checkboxText>
    <ng-content></ng-content>
  </p>
</div>
