import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInjector } from './token.injector.service';
import { UnauthorizedInterceptorService } from './unauthorized.interceptor.service';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptorService,
    multi: true
  }
];
