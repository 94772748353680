import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGmsClientCallbackComponent } from './components/auth-gms-client-callback.component';
import { AuthGmsClientConfig } from './models/auth-gms-client.config';
import { AuthGmsClientConfigService, AuthService } from './services/auth.service';
import { httpInterceptorProviders } from './interceptors';
import { TokenService } from './services/token.service';

@NgModule({
  imports: [OAuthModule.forRoot()],
  declarations: [AuthGmsClientCallbackComponent],
  exports: [AuthGmsClientCallbackComponent],
  providers: [TokenService]
})
export class AuthGmsAngularClientModule {
  static forRoot(config: AuthGmsClientConfig): ModuleWithProviders<AuthGmsAngularClientModule> {
    return {
      ngModule: AuthGmsAngularClientModule,
      providers: [
        AuthService,
        {
          provide: AuthGmsClientConfigService,
          useValue: config
        },
        httpInterceptorProviders
      ]
    };
  }
}
