import { Plugin } from 'chart.js';

export const emptyDoughnutPlugin: Plugin = {
  id: 'emptyDoughnut',
  afterDraw(chart, _args, options) {
    const { datasets } = chart.data;
    const { color, width } = options;
    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      hasData = hasData || dataset.data.length > 0;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = 100;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || '#e5e5e5';
      ctx.arc(centerX, centerY, r || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  }
};
