import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileTypeDto, FileTypeSchemaState, LoadLevel, State } from '@coin/importer/dto/util';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { LogsService } from '../../services/logs/logs.service';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { ToastService } from '../../services/toast/toast.service';
import { UploadService } from '../../services/upload/upload.service';

@Component({
  selector: 'ci-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss']
})
export class UploadPopupComponent implements OnInit, OnDestroy {
  importFile: File;
  importFileName: string;
  selectedFileTypePrefix: string;
  fileTypeKeys = [];
  uploading = false;
  entities: FileTypeDto[];
  progressBarColor: ThemePalette = 'primary';
  progressMode: ProgressBarMode = 'determinate';
  progressValue = 0;
  loading = true;
  loadLevel = LoadLevel.Partial;
  fullLoadCheckBoxState = false;
  private destroy$: Subject<void> = new Subject<void>();
  private uploadUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<UploadPopupComponent>,
    private readonly uploadService: UploadService,
    private toast: ToastService,
    private logService: LogsService,
    private fileTypeService: FileTypeService
  ) {}

  ngOnInit(): void {
    this.fileTypeService.fileTypeSubject$.pipe(takeUntil(this.destroy$)).subscribe(fileTypes => {
      this.loading = false;
      this.entities = fileTypes;
      this.fileTypeKeys = Object.keys(fileTypes);
      [this.selectedFileTypePrefix] = this.fileTypeKeys;
    });
  }

  async applySelectionChange(event: HTMLSelectElement) {
    // @ts-ignore
    this.selectedFileTypePrefix = event.value.prefix;
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      // eslint-disable-next-line prefer-destructuring
      this.importFile = element.files[0];
      this.importFileName = this.importFile.name;
    }
  }

  dropdownDisplayFn(entitiy: FileTypeDto): string {
    return entitiy.displayedName;
  }

  close(forceQuit?: boolean) {
    if (!this.uploading || forceQuit) {
      this.matDialogRef.close();
    } else {
      this.cancelUpload();
    }
  }

  save() {
    this.uploading = true;
    this.uploadService
      .uploadViaPresignedUrl(this.selectedFileTypePrefix, this.importFile, this.loadLevel)
      .pipe(takeUntil(this.destroy$), takeUntil(this.uploadUnsubscribe$))
      .subscribe(this.handleUploading.bind(this), () => {
        this.matDialogRef.close(true);
      });
  }

  private cancelUpload() {
    this.uploadUnsubscribe$.next();
    this.uploadUnsubscribe$.complete();
    this.uploading = false;
    this.importFile = null;
    this.importFileName = null;
    this.progressValue = 0;
    this.toast.info('Upload cancelled');
  }

  private handleUploading(event: HttpEvent<unknown>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.progressValue = Math.round((event.loaded / event.total) * 100);
        break;
      case HttpEventType.Response:
        this.uploading = false;
        this.progressValue = 100;
        this.addTemporaryLog(this.importFileName, this.selectedFileTypePrefix);
        this.close();
        break;
    }
  }

  private addTemporaryLog(name: string, prefix: string): void {
    this.logService.setTemporaryLog({
      id: '',
      uploadedAt: new Date(),
      fileType: this.entities.find(entity => entity.prefix === prefix),
      fileName: name,
      loadLevel: LoadLevel.Full,
      uploadSizeInBytes: 0,
      state: State.Pending,
      filesInS3: true
    });
  }

  toggleFullLoad(event: boolean) {
    if (event) {
      this.loadLevel = LoadLevel.Full;
    } else {
      this.loadLevel = LoadLevel.Partial;
    }
  }

  disableOption(item: FileTypeDto): boolean {
    return item.schemaState !== FileTypeSchemaState.Ready;
  }

  disableComment(item: FileTypeDto): string {
    return ` (Schema is ${item.schemaState?.toString()})`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
