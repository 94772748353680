import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@coin/shared/util-environments';
import { DecisionDto, LogDto, IPaginatedList, ITransactionState, StateUtils, CompositeTransactionDto } from '@coin/importer/dto/util';

import { ActivatedRoute, Router, Params } from '@angular/router';
import { PaginatedResult } from '@coin/shared/util-models';
import { ToastService } from '../toast/toast.service';
import { AcceptedParams, SimpleTableService } from '../../components/simple-table/simple-table.component';
import { FileTypeService } from '../file-type/file-type.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService implements SimpleTableService {
  private tableResetter$ = new BehaviorSubject(false);
  resetter$ = this.tableResetter$.asObservable();

  private newEntrySource$ = new ReplaySubject<LogDto>(null);
  newEntry$ = this.newEntrySource$.asObservable();

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private fileTypeService: FileTypeService
  ) {}

  public resetTable(value: boolean) {
    this.tableResetter$.next(value);
  }

  queryPagedAndFilteredData(params: AcceptedParams) {
    const sortedParams = this.sortParams(params);
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: sortedParams
    });
    return this.getImportLogs(sortedParams).pipe(map(this.fitToPaginatedResult));
  }

  getImportLogs(params: Params): Observable<IPaginatedList<LogDto>> {
    return this.http.get<IPaginatedList<LogDto>>(environment.api.importerService, { params }).pipe(catchError(this.handleError.bind(this)));
  }

  getSingleImportLog(id: string): Observable<LogDto> {
    return this.http.get<LogDto>(`${environment.api.importerService}/${id}`).pipe(catchError(this.handleError.bind(this)));
  }

  getPresignedDownloadUrl(id: string, value: string, format: 'json' | 'csv'): Observable<string> {
    const url = `${environment.api.importerService}/downloadUrl`;

    let paramForDownload;

    if (format === 'json') {
      paramForDownload = {
        responseType: 'json'
      };
    } else {
      paramForDownload = {
        responseType: 'text'
      };
    }

    const params = {
      entity: id,
      value
    };
    return this.http.get<{ signedUrl: string }>(url, { params }).pipe(
      switchMap(({ signedUrl }) => {
        return this.http.get(signedUrl, paramForDownload);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  getPreviousQueuedLogs(id: string): Observable<LogDto[]> {
    return this.http.get<LogDto[]>(`${environment.api.importerService}/getPreviousUnfinishedJobs/${id}`);
  }

  rejectPreviousQueuedLogs(id: string): Observable<string[]> {
    return this.http.post<string[]>(`${environment.api.importerService}/skipPreviousUnfinishedJobs/${id}`, {});
  }

  sendDecision(id: string, prefix: string, decisionData: Partial<DecisionDto>): Observable<LogDto> {
    const url = `${environment.api.importerService}/${id}/decision`;
    return this.http.put(url, { prefix, decisionData }).pipe(catchError(this.handleError.bind(this)));
  }

  setTemporaryLog(element: LogDto): void {
    this.newEntrySource$.next(element);
  }

  checkProgressUpdate(ids: Partial<LogDto>[]): Observable<LogDto[]> {
    return this.http.post<LogDto[]>(`${environment.api.importerService}/reloadLogs`, ids).pipe(tap(this.handleFinalProgressStep.bind(this)));
  }

  private handleFinalProgressStep(logs: LogDto[]) {
    logs.forEach(log => {
      if (StateUtils.isSuccessfullyFinishedLogState(log.state)) {
        this.fileTypeService.loadFileTypeToSubject(log.fileType.id);
      }
    });
  }

  private sortParams(params: AcceptedParams): Params {
    const filteredParams: Params = {};
    let param: keyof typeof params;
    for (param in params) {
      const value = decodeURI(params[param] as string);
      switch (param) {
        case 'page': {
          const page = parseInt(value);
          if (page > 1) {
            // page=1 is default and should not show in url
            filteredParams[param] = page;
          }
          break;
        }
        case 'limit': {
          const limit = parseInt(value);
          if (limit > 0 && limit !== environment.importerTableLimit) {
            // limit=25 is default and should not show in url
            filteredParams[param] = limit;
          }
          break;
        }
        case 'search':
          if (value) {
            this.toast.info('Search-Parameter not yet implemented');
          }
          break;
        default:
          if (value.length > 0) {
            filteredParams[param] = value.includes('&') ? value.split('&') : value;
          }
          break;
      }
    }
    return filteredParams;
  }

  private fitToPaginatedResult(obj: IPaginatedList<LogDto>): PaginatedResult<LogDto> {
    const result: PaginatedResult<LogDto> = {
      content: obj.items,
      currentPage: obj.meta.currentPage,
      nextPage: obj.meta.currentPage + 1,
      pageCount: obj.meta.totalPages,
      pageSize: obj.meta.itemsPerPage,
      total: obj.meta.totalItems
    };
    return result;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The Request could not be sent - please try again later');
    } else if (error.status === 404) {
      this.toast.errorWithRedirect(`The requested page could not be found.`, '404');
    } else {
      this.toast.error(`Error: "${error.error?.message || 'unknown'}"`);
    }
    return throwError(error);
  }

  getCompositeTransactionById(id: string): Observable<CompositeTransactionDto> {
    const url = `${environment.api.importerService}/${id}/compositeTransaction`;
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }

  getTransactionStatesById(id: string): Observable<ITransactionState> {
    const url = `${environment.api.importerService}/${id}/coinTransactionState`;
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }
}
