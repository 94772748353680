function getFileName(fullPath: string): string {
  const fileName = fullPath.split('/').pop();
  return fileName || fullPath;
}

export class GeneralUtilities {
  public static clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  public static downloadFile(content: string, name: string, format: 'json' | 'csv') {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/${format};charset=UTF-8,${encodeURIComponent(content)}`);
    element.setAttribute('download', getFileName(name));
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
