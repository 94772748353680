import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileTypeDto, LogDto, ValidationRuleDto } from '@coin/importer/dto/util';
import { environment } from '@coin/shared/util-environments';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIGatewayResponse } from '@coin/importer/common/data-access';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class FileTypeService {
  fileTypeSource: FileTypeDto[];
  fileTypeSubject$ = new BehaviorSubject<FileTypeDto[]>([]);

  constructor(
    private http: HttpClient,
    private toast: ToastService
  ) {}

  api = [environment.api.importerService, 'fileTypes'].join('/');

  private updateFileTypes(fileTypes: FileTypeDto[]) {
    this.fileTypeSource = fileTypes;
    this.fileTypeSubject$.next(this.fileTypeSource);
  }

  private updateFileType(newFt: FileTypeDto) {
    this.fileTypeSource = this.fileTypeSource.map(ft => {
      if (ft.id === newFt.id) {
        newFt.validationRules = ft.validationRules;
        return newFt;
      }
      return ft;
    });
    this.fileTypeSubject$.next(this.fileTypeSource);
  }

  private addFileType(newFt: FileTypeDto) {
    this.fileTypeSource.push(newFt);
    this.fileTypeSubject$.next(this.fileTypeSource);
  }

  private removeFileType(id: FileTypeDto['id']) {
    this.fileTypeSource = this.fileTypeSource.filter(ft => ft.id !== id);
    this.fileTypeSubject$.next(this.fileTypeSource);
  }

  async loadFileTypeToSubject(id: FileTypeDto['id']) {
    if (id) {
      const result: FileTypeDto = await this.http
        .get<FileTypeDto>([this.api, id].join('/'))
        .pipe(catchError(this.handleError.bind(this)))
        .toPromise();
      this.updateFileType(result);
    } else {
      this.toast.error('No identifier for file type provided');
    }
  }

  async loadFileTypesToSubject() {
    const result = await this.http
      .get<FileTypeDto[]>(this.api)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
    this.updateFileTypes(result);
  }

  async updateFileTypeProperties(fileType: Partial<FileTypeDto>): Promise<boolean> {
    const api = [this.api, fileType.id].join('/');
    const result: FileTypeDto = await this.http
      .put(api, fileType)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
    this.updateFileType(result);
    return true;
  }

  async deleteFileType(fileType: FileTypeDto): Promise<boolean> {
    const api = [this.api, fileType.id].join('/');
    const result: boolean = await this.http
      .delete(api)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
    if (result) this.removeFileType(fileType.id);
    return result;
  }

  async createFileType(fileType: Partial<FileTypeDto>): Promise<boolean> {
    const result: FileTypeDto = await this.http
      .post(this.api, fileType)
      .pipe(catchError(this.handleError.bind(this)))
      .toPromise();
    this.addFileType(result);
    return true;
  }

  getLastSuccessfulLog(fileType: FileTypeDto): Observable<LogDto> {
    const url = [this.api, fileType.prefix, 'lastSuccessfulLog'].join('/');
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }

  deleteObjects(fileType: FileTypeDto): Observable<APIGatewayResponse> {
    const url = [this.api, fileType.prefix, 'deleteObjects'].join('/');
    return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
  }

  getSchemaRules(fileTypeId: FileTypeDto['id']): Observable<ValidationRuleDto[]> {
    const url = [this.api, fileTypeId, 'schema'].join('/');
    return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
  }

  updateValidationRules(rules: ValidationRuleDto[]): Observable<boolean> {
    const url = [this.api, 'schema'].join('/');
    return this.http.put(url, rules).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.toast.error('The Request could not be sent - please try again later');
    } else if (error.status === 404) {
      this.toast.errorWithRedirect(`The requested page could not be found.`, '404');
    } else {
      this.toast.error(`Error: "${error.error?.message || 'unknown'}"`);
    }
    return throwError(error);
  }
}
