import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LottieFilesService {
  currLottieFiles = {};

  constructor(private http: HttpClient) {}

  setLottieFile(name, obj) {
    this.currLottieFiles[name] = obj;
  }

  getLottieFile(name) {
    return this.currLottieFiles[name];
  }

  getLottieJson(name): Observable<unknown> {
    return this.http.get(`assets/svgs/icon-data/${name}.json`);
  }
}
