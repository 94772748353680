import { Component, EventEmitter, Inject, Output, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportAttributesDto, FileTypeDto } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { UploadPopupComponent } from '../upload-popup/upload-popup.component';
import { GeneralUtilities } from '../../utility/general-utilities';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'ci-export-attributes-popup',
  templateUrl: './export-attributes-popup.component.html',
  styleUrls: ['./export-attributes-popup.component.scss']
})
export class ExportAttributesPopupComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<UploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fileTypeService: FileTypeService,
    private toast: ToastService
  ) {}

  Action = PopupAction;
  item = this.data['item'] as FileTypeDto;
  action = this.data['action'] as PopupAction;
  uploading = false;
  validExportAttributes = false;
  requiredExportAttributes = false;
  originalAttributes: ExportAttributesDto[];

  ngOnInit(): void {
    this.originalAttributes = GeneralUtilities.clone(this.item.exportAttributes);
  }

  close() {
    this.item.exportAttributes = [...this.originalAttributes];
    this.matDialogRef.close();
  }

  async save() {
    this.uploading = true;
    const isUpdated = await this.fileTypeService.updateFileTypeProperties(this.item);
    if (isUpdated) {
      this.close();
    } else {
      this.toast.error('could not update attributes');
    }
  }
}
