import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { Observable, forkJoin, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from '../../services/toast/toast.service';
import { UserAuthReq } from '../models/user-auth.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralUserService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  public getUserByToken(): Observable<UserAuthReq> {
    return forkJoin([from(this.authService.getAccessToken()), from(this.authService.getIdToken())]).pipe(
      map(([accessToken, identityToken]) => ({
        accessToken,
        identityToken
      })),
      switchMap(tokens => this.httpClient.post<UserAuthReq>(`${environment.api.baseUrl}/user/info`, tokens)),
      catchError((res: HttpErrorResponse) => {
        const errorMessage = `Error retrieving user. ${res?.error?.reason || ''}`;
        this.toast.error(errorMessage);
        return throwError(errorMessage);
      }),
      map(this.addUserInfos.bind(this))
    );
  }

  private addUserInfos(res: UserAuthReq): UserAuthReq {
    if (res.token) {
      const decodedUser = this.authService.decodeToken(res.token);
      res.principal.company = decodedUser['custom:company'] || decodedUser.company;
      res.principal.country = decodedUser['custom:country'] || decodedUser.ctry;
      res.principal.org_code = decodedUser['custom:org_code'] || decodedUser.org_code;
    }
    return res;
  }
}
