<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>Edit Export Attributes</h3>
    </div>

    <div class="items">
      <ci-export-attributes-form
        (allValid)="validExportAttributes = $event"
        (allRequired)="requiredExportAttributes = $event"
        [entity]="item"
        [action]="action"
      ></ci-export-attributes-form>
    </div>

    <div class="buttons">
      <coin-action-button class="secondary" svgIcon="cancel" (click)="close()">
        Cancel
      </coin-action-button>

      <coin-action-button
        class="primary"
        svgIcon="upload"
        (click)="save()"
        [disabled]="
          uploading || !validExportAttributes || !requiredExportAttributes
        "
      >
        Save
      </coin-action-button>
    </div>

    <ng-container *ngIf="action == Action.Edit">
      <div class="info-footer">
        <p [class.error]="!requiredExportAttributes">
          Fields marked with * are mandatory.
        </p>
      </div>
    </ng-container>
  </div>
</div>
