<div class="container">
  <div class="content">
    <div class="dialog-headline">
      <h3>{{ action }} Structure List Entry</h3>
    </div>

    <div class="box">
      <div class="row">
        <coin-input
          type="text"
          [(ngModel)]="item.TargetOrgCode"
          placeholder="TargetOrgCode"
          label="TargetOrgCode"
          [disabled]="action === Action.Edit || action === Action.Delete"
          [errorMsg]="validateString(item.TargetOrgCode)"
        ></coin-input>
        <coin-input
          type="text"
          [(ngModel)]="item.Position"
          placeholder="Position"
          label="Position"
          [disabled]="action === Action.Delete"
          [errorMsg]="validateString(item.Position)"
        ></coin-input>
      </div>

      <div class="row">
        <coin-input
          type="text"
          [(ngModel)]="item.HeadGid"
          placeholder="HeadGid"
          label="HeadGid"
          [disabled]="action === Action.Delete"
          [errorMsg]="validateString(item.HeadGid)"
        ></coin-input>

        <div class="row">
          <coin-dropdown
            [(ngModel)]="item.Acting"
            placeholder="Acting"
            label="Acting"
            [items]="acting"
            [disabled]="action === Action.Delete"
          ></coin-dropdown>
        </div>

        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            Cancel
          </coin-action-button>

          <div *ngIf="action === Action.Edit">
            <coin-action-button
              class="primary"
              svgIcon="upload"
              (click)="update()"
              [disabled]="uploading || !validate()"
            >
              Update
            </coin-action-button>
          </div>

          <div *ngIf="action === Action.New" #new>
            <coin-action-button
              class="primary"
              svgIcon="upload"
              (click)="create()"
              [disabled]="uploading || !validate()"
            >
              Create
            </coin-action-button>
          </div>

          <div *ngIf="action === Action.Delete" #new>
            <coin-action-button
              class="primary"
              svgIcon="upload"
              (click)="delete()"
              [disabled]="uploading || !validate()"
            >
              Delete
            </coin-action-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
