<div class="input-container">
  <mat-form-field
    class="admin-form-field-outline admin-form-field-outline--shadow admin-form-field-outline--dynamic-height"
    appearance="outline"
    floatLabel="always"
  >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-chip-grid
      #chipList
      aria-label="Chip selection"
      class="modified-mat-chip-list"
    >
      <mat-chip-option *ngFor="let chip of chips" (removed)="remove(chip)">
        {{ chip }}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-option>
      <input
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </mat-form-field>
</div>
