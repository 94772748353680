<div class="chartFrame middle">
  <div class="chartCenter" *ngIf="chartData.noData; else data">
    <p>no data</p>
  </div>
  <ng-template #data>
    <div class="chartCenter">
      <h1 *ngIf="chartData.first">
        {{ chartData.first[0].toString() | percentage: chartData.first[1].toString() }}
      </h1>
      <p *ngIf="chartData.first">{{ chartData.first[2] }}</p>
      <p *ngIf="chartData.second">=================</p>
      <h1 *ngIf="chartData.second">
        {{ chartData.second[0].toString() | percentage: chartData.second[1].toString() }}
      </h1>
      <p *ngIf="chartData.second">{{ chartData.second[2] }}</p>
      <p *ngIf="!chartData.first && !chartData.second">
        <b>distribution of </b>
      </p>
      <p *ngIf="!chartData.first && !chartData.second">
        <b>uploads per file type</b>
      </p>
    </div>
  </ng-template>
  <div class="chartRing" style="display: block">
    <canvas baseChart [data]="chartData.data" [labels]="chartData.labels" [options]="chartData.options" [type]="'doughnut'"> </canvas>
  </div>
</div>
