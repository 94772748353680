import { UserAuthReq } from '../models/user-auth.model';

export class LoadLoggedInUser {
  static readonly type = '[User] Load Logged In User';
}

export class LoadLoggedInUserSuccess {
  static readonly type = '[User] Load Logged In User Success';
  constructor(public readonly payload: UserAuthReq) {}
}
