<div style="flex: 1"></div>
<div class="profile-main" *ngIf="user && (token.isLoggedIn$ | async)">
  <div
    (contextmenu)="onRightClick($event)"
    class="image"
    *ngIf="userImageSrc && userImageSrc !== ''"
    [ngStyle]="{ 'background-image': 'url(' + userImageSrc + ')' }"
  ></div>
  <div class="avatar" *ngIf="!userImageSrc || userImageSrc === ''" slot="start">
    <mat-icon class="user-icon">person</mat-icon>
  </div>
  <div
    class="profile-info"
    [ngClass]="{ 'show-user-menu': showMenu }"
    (click)="openMenu()"
  >
    <p style="text-transform: uppercase">
      {{ user.org_code ? user.org_code + ', ' : '' }}
      {{ user.country }}
    </p>
    <p class="bold">{{ user?.firstname }} {{ user?.lastname }}</p>

    <ng-container *ngIf="token.remainingTimeText$ | async as remainingTime">
      <p
        *ngIf="token.timeWarningStyle$ | async as timeWarning"
        class="remaining-time"
        [ngClass]="{
          'show-yellow': timeWarning === 'alarming',
          'show-red': timeWarning === 'urgent'
        }"
        (mouseenter)="remainingTimeHover('enter')"
        (mouseleave)="remainingTimeHover('leave')"
        (click)="renewToken($event)"
      >
        <mat-icon *ngIf="!remainingTimeHovered" @fade>access_time</mat-icon>
        <mat-icon *ngIf="remainingTimeHovered" @slide class="renew"
          >autorenew</mat-icon
        >
        {{ remainingTime }}
      </p>
    </ng-container>
  </div>
  <div
    class="menu-part-element mat-elevation-z3"
    *ngIf="showMenu"
    @shrinkExpand
    style="display: flex; flex-direction: column"
  >
    <div class="menu-element-item disabled" style="height: 79px">
      <div class="spacing-holder">
        <div class="border"></div>
      </div>
    </div>
    <div
      class="menu-element-item"
      style="position: relative"
      [ngClass]="{ 'mei-disabled': menuItem.disabled }"
      @shrinkExpand
      *ngFor="let menuItem of menuItems; let i = index"
    >
      <div
        (click)="openMenuItem(menuItem)"
        class="spacing-holder"
        style="position: relative"
      >
        <div class="avatar menu-entry" slot="start">
          <mat-icon class="user-icon">{{ menuItem.icon }}</mat-icon>
        </div>
        <p style="margin-top: auto; margin-bottom: auto" class="bold">
          {{ menuItem.name }}
        </p>

        <div class="border" *ngIf="i !== menuItems.length - 1"></div>
      </div>
    </div>
  </div>

  <div
    class="menu-part-element-behind"
    *ngIf="showMenu"
    style="display: flex; flex-direction: column"
  >
    <div class="menu-element-item disabled" style="height: 79px">
      <div class="spacing-holder"></div>
    </div>
    <div
      class="menu-element-item"
      *ngFor="let menuItem of menuItems; let i = index"
    >
      <div class="spacing-holder">
        <div
          class="extra-item mat-elevation-z3"
          [ngClass]="{ active: menuItem.submenuactive }"
        >
          <div class="spacing-holder"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="profile" *ngIf="!user || !(token.isLoggedIn$ | async)">
  <div
    class="image"
    *ngIf="languageSelected"
    [ngStyle]="{
      'background-image': 'url(/assets/svgs/flags/' + languageSelected + '.svg)'
    }"
  ></div>
  <div
    class="profile-info"
    [ngClass]="{ 'show-user-menu': showMenu }"
    (click)="openMenu()"
  >
    <p class="bold">{{ languageSelected }}</p>
  </div>
  <div
    class="menu-part-element mat-elevation-z3"
    *ngIf="showMenu"
    @shrinkExpand
    style="display: flex; flex-direction: column"
  >
    <div class="menu-element-item disabled" style="height: 79px">
      <div class="spacing-holder">
        <div class="border"></div>
      </div>
    </div>
    <div
      class="menu-element-item"
      @shrinkExpand
      *ngFor="let lang of selectableLangs | keyvalue; let i = index"
    >
      <div class="spacing-holder">
        <div
          class="image"
          *ngIf="languageSelected"
          [ngStyle]="{
            'background-image': 'url(/assets/svgs/flags/' + lang.key + '.svg)'
          }"
        ></div>
        <p style="margin-top: auto; margin-bottom: auto" class="bold">
          {{ 'general.' + lang.key }}
        </p>
        <div class="border" *ngIf="i !== menuItems.length - 1"></div>
      </div>
    </div>
  </div>
</div>
<div style="flex: 1"></div>
