<div class="progress">
  <mat-progress-bar *ngIf="!transactionStates" value="percentage" [mode]="mode"></mat-progress-bar>
  <div class="target-view" *ngFor="let transactionState of transactionStates">
    <p>
      {{ transactionState?.ProcessTitle || transactionState?.ProcessType }}:
      {{ transactionState?.State }}
      ({{ (transactionState?.ProgressCount && transactionState?.TotalCount) ?
        (transactionState?.ProgressCount / transactionState?.TotalCount * 100 | number:'1.2-2')
        : 0 }} %)</p>
		<mat-progress-bar [value]="calculateTransactionProcessPercentage(transactionState)"
			[mode]="mode"></mat-progress-bar>
	</div>
</div>
