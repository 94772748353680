import { DestroyRef, inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

export const TOAST_OPTIONS: Partial<GlobalConfig> = {
  preventDuplicates: true,
  positionClass: 'toast-bottom-right',
  timeOut: 3000,
  progressBar: true
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private destroy$ = inject(DestroyRef);

  constructor(
    private toast: ToastrService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  success(text: string, title?: string): void {
    this.toast.success(text, title);
  }

  info(text: string, title?: string): void {
    this.toast.info(text, title);
  }

  warn(text: string, title?: string): void {
    this.toast.warning(text, title);
  }

  error(text: string, title?: string): void {
    this.toast.error(text, title);
  }

  errorWithRedirect(text: string, path: string): void {
    this.ngZone.run(() => {
      const element = this.toast.error(text, 'Redirecting...');
      element.onHidden.pipe(take(1), takeUntilDestroyed(this.destroy$)).subscribe(_ => {
        this.router.navigate([path]);
      });
    });
  }
}
