import { State } from './state.enum';

export class StateUtils {
  static finishedLogStates: State[] = [State.Patched, State.Published, State.Rejected, State.Failed];

  static processingLogStates: State[] = [State.Staging, State.Patching, State.Publishing, State.Rejecting];

  static startedLogStates: State[] = [State.Staging, State.Staged, State.Patching, State.Publishing, State.Rejecting];

  static skippableLogStates: State[] = [State.Pending, State.Staged];

  static successfullyFinishedLogStates: State[] = [State.Patched, State.Published];

  static isFinishedLogState = (state: State): boolean => this.finishedLogStates.includes(state);

  static isProcessingLogState = (state: State): boolean => this.processingLogStates.includes(state);

  static isSkippableLogState = (state: State): boolean => this.skippableLogStates.includes(state);

  static isSuccessfullyFinishedLogState = (state: State): boolean => this.successfullyFinishedLogStates.includes(state);

  static isExpectedToChange = (state: State): boolean => state === State.Pending || this.isProcessingLogState(state);
}
