import { Component, Input, OnInit } from '@angular/core';
import { StatsDto, ValidationDto } from '@coin/importer/dto/util';
import { SimpleTableHeader } from '../simple-table/simple-table.component';

@Component({
  selector: 'ci-validation-list',
  templateUrl: './validation-list.component.html',
  styleUrls: ['./validation-list.component.scss']
})
export class ValidationListComponent implements OnInit {
  @Input() public importStats: StatsDto | undefined;
  dataSource: ValidationDto[];
  totalChanges: number;
  totalChangedDatasets: number;
  passed: number;
  failed: number;

  // columnsInTable: string[] = ['type', 'sum', 'share'];
  public validationsHeader: SimpleTableHeader[] = [
    {
      title: 'Failed Validation Rules',
      key: 'type',
      width: '2fr'
    },
    {
      title: 'affected changes',
      key: 'sum',
      special: 'number'
    },
    {
      title: '% of validated changes'
    }
  ];

  constructor() {}

  ngOnInit(): void {
    this.filterDataSource();
    this.getAccumulatedValues();
  }

  private filterDataSource(): void {
    if (this.importStats?.validations) {
      this.dataSource = this.importStats.validations.filter(validation => typeof validation.sum === 'number' && validation.validationName !== 'pass').sort((a, b) => b.sum - a.sum);

      for (let i = 0; i < this.dataSource.length; i++) {
        const element = this.dataSource[i];
        if (element.validationName === 'pass') {
          this.dataSource.splice(i, 1);
          this.dataSource.splice(0, 0, element);
          break;
        }
      }
      for (let i = 0; i < this.dataSource.length; i++) {
        const element = this.dataSource[i];
        if (element.validationName === 'noValidation') {
          this.dataSource.splice(i, 1);
          this.dataSource.splice(0, 0, element);
          break;
        }
      }
    }
  }

  getAccumulatedValues(): void {
    if (!this.importStats?.validations || !Array.isArray(this.importStats.validations)) return;

    let sumTotal = 0;
    let sumPass = 0;
    let sumInvalid = 0;

    this.importStats.validations.forEach(validation => {
      sumTotal += validation.sum;
      if (validation.validationName === 'pass') {
        sumPass += validation.sum;
      } else if (validation.validationName !== 'noValidation') {
        sumInvalid += validation.sum;
      }
      this.totalChangedDatasets = this.importStats.changedDatasets;
      this.totalChanges = sumTotal;
      this.passed = sumPass;
      this.failed = sumInvalid;
    });
  }
}
