<div class="inner-content sm-header">
  <div class="content">
    <h3>{{ title }}</h3>
    <div>
      <div>
        <i class="material-icons nav" (click)="openUploadPage()">
          arrow_upward
        </i>
      </div>
      <div>
        <i class="material-icons nav" (click)="openRepublishPage()">
          published_with_changes
        </i>
      </div>
      <div>
        <i class="material-icons nav" (click)="openSettingsPage()">
          settings
        </i>
      </div>
      <div>
        <i class="material-icons nav" (click)="openOrgManagementPage()">
          transfer_within_a_station
        </i>
      </div>
    </div>
  </div>
</div>
