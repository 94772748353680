<div class="container" [class.larger]="showConfirmation">
  <div class="content">
    <div class="dialog-headline">
      <h3>Reject Queued Elements</h3>
    </div>
    <div class="loading-spinner" *ngIf="loadingPopupData">
      <app-turbine [contrast]="true"></app-turbine>
    </div>
    <div class="box" *ngIf="!loadingPopupData">
      <div class="group" *ngIf="logsAheadInQueue.length; else noaction">
        <p>
          There are currently the following
          {{ logsAheadInQueue.length }} uploads ahead in Queue:
        </p>
        <ul>
          <li *ngFor="let log of logsAheadInQueue">
            <p>
              {{ log.uploadedAt | date : 'MMM d, y' }}:
              {{ log.fileName | fileName }}
              ({{ log.state | uppercase }})
            </p>
          </li>
        </ul>
        <p>
          Do you want to skip these uploads? This will automatically reject the
          listed uploads.
        </p>
      </div>
      <ng-template #noaction>
        <div class="group">
          <p></p>
          <p>This element is already on the top position of the queue.</p>
          <p>Cannot remove any previous pending uploads.</p>
        </div>
      </ng-template>

      <div class="buttons">
        <coin-action-button
          class="secondary"
          svgIcon="cancel"
          [disabled]="showConfirmation"
          (click)="close()"
        >
          Cancel
        </coin-action-button>
        <coin-action-button
          class="secondary"
          svgIcon="cancel"
          [disabled]="
            showConfirmation || !currentLog || !logsAheadInQueue.length
          "
          (click)="showConfirmation = true"
        >
          Begin staging of current log
        </coin-action-button>
      </div>
      <div class="box" *ngIf="showConfirmation">
        <div class="group">
          <p>
            <strong>
              This will reject {{ logsAheadInQueue.length }} previous uploads.
              Are you sure?
            </strong>
          </p>
        </div>

        <div class="buttons">
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            (click)="close()"
          >
            Cancel
          </coin-action-button>
          <coin-action-button
            class="secondary"
            svgIcon="cancel"
            [disabled]="loadingPopupData"
            (click)="submit()"
          >
            Yes - Reject previous uploads
          </coin-action-button>
        </div>
      </div>
    </div>
  </div>
</div>
