import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, TinyHelpers } from '@coin/importer/common/ui';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { distinct, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from '../../services/toast/toast.service';
import { AuthService } from './auth.service';

const MINUTE = 60;
const HOUR = MINUTE * 60;

@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public globalInterval: unknown;

  public remainingTime$ = new BehaviorSubject<number>(null);
  public tokenChecker$: Subscription;

  public isLoggedIn$ = new BehaviorSubject(false);
  public remainingTimeText$: Observable<string>;
  public timeWarningStyle$: Observable<'ok' | 'alarming' | 'urgent'>;

  private tokenNotifications$: Subscription;

  constructor(
    private toast: ToastService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.remainingTime$
      .pipe(
        map(remainingTime => remainingTime > 0 || remainingTime === null),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.isLoggedIn$);

    this.remainingTimeText$ = this.remainingTime$.pipe(map(remainingTime => this.secondsToTimeText(remainingTime)));
    this.timeWarningStyle$ = this.remainingTime$.pipe(
      map(remainingTime => {
        if (remainingTime < 1 * MINUTE) {
          return 'urgent';
        }
        if (remainingTime < 5 * MINUTE) {
          return 'alarming';
        }
        return 'ok';
      })
    );

    this.showTokenNotifications();
  }

  private showTokenNotifications(): void {
    if (this.tokenNotifications$) {
      return;
    }

    this.tokenNotifications$ = this.remainingTime$
      .pipe(
        map(remainingTime => Math.round(remainingTime)),
        distinct(),
        filter(remainingTime => remainingTime === 5 * MINUTE || remainingTime === 1 * MINUTE),
        tap(remainingTime => {
          this.dialog
            .open(ConfirmationDialogComponent, {
              data: {
                headline: 'Session Info',
                msg: `Session ends in ${this.secondsToTimeText(remainingTime)}.`,
                confirmMsg: 'Renew Token',
                cancelMsg: 'Cancel'
              }
            })
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
              if (!result) {
                return;
              }

              this.renewToken();
            });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private secondsToTimeText(seconds: number): string {
    if (seconds === undefined) {
      return '';
    }
    if (seconds > HOUR) {
      return `${Math.round(seconds / HOUR)} h`;
    }
    if (seconds > 5 * MINUTE) {
      return `${Math.round(seconds / MINUTE)} min`;
    }
    return `${Math.round(seconds)} sec`;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async renewToken(): Promise<void> {
    try {
      await this.authService.refreshToken();
      this.toast.success('Token successfully renewed');
      this.startCheckingTimeTillLogout();
    } catch {
      this.authService.login();
    }
  }

  public startCheckingTimeTillLogout(): void {
    if (this.tokenChecker$) {
      return;
    }

    // TODO: use OAuthService 'token_expires' event instead
    this.tokenChecker$ = interval(1000)
      .pipe(
        map(() => this.authService.getRemainingTime()),
        tap(remainingTime => this.remainingTime$.next(remainingTime)),
        filter(remainingTime => TinyHelpers.isBetween(remainingTime, -10, 0)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.dialog
          .open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              headline: 'Session Info',
              msg: 'Your Session has ended',
              confirmMsg: 'Log In again'
            }
          })
          .afterClosed()
          // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
          .subscribe(() => this.renewToken());

        this.tokenChecker$.unsubscribe();
        this.tokenChecker$ = null;
      });
  }
}
