import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { forkJoin, lastValueFrom } from 'rxjs';
import { LoadUser } from '@coin/importer/common/ui';
import { map } from 'rxjs/operators';
import { LoadLoggedInUser, UserState } from '../store';
import { AuthService } from './auth.service';
import { PermissionAction, PermissionResource, Permission } from '../models/role.model';
import { CoinUser } from '../models/user-auth.model';

export interface RoleGuardData {
  resource: PermissionResource;
  actions?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.authService.isAuthenticated$.value) {
      const allowedResources: RoleGuardData[] = route.data?.allowedResources || [];
      return forkJoin([this.store.dispatch(new LoadUser()), this.store.dispatch(new LoadLoggedInUser())])
        .pipe(
          map(res => {
            const loggedInUser = this.store.selectSnapshot(UserState.loggedInUser);
            if (!this.hasPermissions(loggedInUser, allowedResources)) {
              this.router.navigate(['unauthorized']);
              return false;
            }
            return !!res;
          })
        )
        .toPromise();
    }
    this.authService.login(state.url);
    return false;
  }

  private hasPermissions(user: CoinUser, requiredResources: RoleGuardData[]): boolean {
    const permissions: Permission[] = user?.roles?.reduce((acc, role) => [...acc, ...role.permissions], []);

    const masterRequirement: boolean = permissions?.some(permission => permission.resource === PermissionResource.All);

    if (masterRequirement || !requiredResources?.length) {
      return true;
    }

    return requiredResources?.some(role => permissions.some(permission => permission.resource === role.resource && this.actionInRequired(permission.action, role.actions)));
  }

  private actionInRequired(action: string, requiredActions: string[]) {
    return !requiredActions || requiredActions.length === 0 || action === PermissionAction.All || requiredActions.includes(action);
  }
}
