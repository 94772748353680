<ci-sub-header title="AMPERE Workaround"></ci-sub-header>

<div class="sub-nav-bar">
  <coin-action-button
    mat-raised-button
    [ngClass]="openList === 'transfer' ? 'primary' : 'secondary'"
    (click)="openTransferListPage()"
  >
    Transfer List
  </coin-action-button>
  <coin-action-button
    mat-raised-button
    [ngClass]="openList === 'structure' ? 'primary' : 'secondary'"
    (click)="openStructureListPage()"
  >
    Structure List
  </coin-action-button>
  <coin-action-button
    mat-raised-button
    [ngClass]="openList === 'email' ? 'primary' : 'secondary'"
    (click)="openEmailListPage()"
  >
    Email List
  </coin-action-button>
</div>

<ng-container *ngIf="openList === 'transfer'">
  <ci-transfer_list></ci-transfer_list>
</ng-container>
<ng-container *ngIf="openList === 'structure'">
  <ci-structure_list></ci-structure_list>
</ng-container>
<ng-container *ngIf="openList === 'email'">
  <ci-email_list></ci-email_list>
</ng-container>
