import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { CoinUser } from '../models/user-auth.model';
import { GeneralUserService } from '../services/general-user.service';
import * as userActions from './user.actions';

export interface UserStateModel {
  loggedInUser: CoinUser;
  token: string;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    token: '',
    loggedInUser: null
  }
})
@Injectable()
export class UserState {
  constructor(private userService: GeneralUserService) {}

  @Selector()
  static loggedInUser(state: UserStateModel) {
    return state.loggedInUser;
  }

  @Selector()
  static token(state: UserStateModel) {
    return state.token;
  }

  @Selector()
  static allPermissionsLoggedInUser(state: UserStateModel) {
    return state.loggedInUser.roles.reduce((acc, role) => [...acc, ...role.permissions], []);
  }

  @Action(userActions.LoadLoggedInUser)
  loadLoggedInUser(ctx: StateContext<UserStateModel>) {
    if (ctx.getState().loggedInUser?.gid) {
      return;
    }

    return this.userService.getUserByToken().pipe(switchMap(user => ctx.dispatch(new userActions.LoadLoggedInUserSuccess(user))));
  }

  @Action(userActions.LoadLoggedInUserSuccess)
  loadLoggedInUserSuccess({ patchState }: StateContext<UserStateModel>, { payload }: userActions.LoadLoggedInUserSuccess) {
    patchState({ loggedInUser: payload.principal });
  }
}
