import { FileTypeSchemaType } from './file-type-schema-type.enum';

export enum ValidationRuleOperator {
  gtLength = '> (length)',
  ltLength = '< (length)',
  eqLength = '= (length)',
  notLength = '!= (length)',
  notNull = 'is not null',
  isNull = 'is null',
  contains = 'contains',
  notContains = 'not contains',
  true = 'true',
  false = 'false',
  gt = '>',
  lt = '<',
  eq = '==',
  not = '!='
}

export function getReducedOperators(type: FileTypeSchemaType): ValidationRuleOperator[] {
  switch (type) {
    case FileTypeSchemaType.list:
      return [
        ValidationRuleOperator.gtLength,
        ValidationRuleOperator.ltLength,
        ValidationRuleOperator.eqLength,
        ValidationRuleOperator.notLength,
        ValidationRuleOperator.isNull,
        ValidationRuleOperator.notNull
      ];
    case FileTypeSchemaType.string:
      return [
        ValidationRuleOperator.eq,
        ValidationRuleOperator.not,
        ValidationRuleOperator.isNull,
        ValidationRuleOperator.notNull,
        ValidationRuleOperator.gtLength,
        ValidationRuleOperator.ltLength,
        ValidationRuleOperator.eqLength,
        ValidationRuleOperator.notLength,
        ValidationRuleOperator.contains,
        ValidationRuleOperator.notContains
      ];
    case FileTypeSchemaType.float:
    case FileTypeSchemaType.int:
      return [
        ValidationRuleOperator.isNull,
        ValidationRuleOperator.notNull,
        ValidationRuleOperator.gt,
        ValidationRuleOperator.lt,
        ValidationRuleOperator.eq,
        ValidationRuleOperator.not
      ];
    case FileTypeSchemaType.boolean:
      return [ValidationRuleOperator.isNull, ValidationRuleOperator.notNull, ValidationRuleOperator.true, ValidationRuleOperator.false];
    case FileTypeSchemaType.object:
      return [ValidationRuleOperator.isNull, ValidationRuleOperator.notNull];
    default:
      return [];
  }
}
